export const REPORT_TYPES = [
    {
        name: "Simple",
        id: 1,
        type: "state-types-documentDate-receptionDate",
        key: "simple"
    },
    {
        name: "Impuestos",
        id: 2,
        type: "year-month",
        key: "tax"
    },  
    {
        name: "ATS",
        id: 3,
        type: "year-month",
        key: "ats"
    },
]

export const RETENTION_REPORT_TYPES = [
    {
        name: "Reporte por Estados",
        id: 1,
        type: "state-documentDate-receptionDate",
        key: "state"
    },
    {
        name: "Reporte por Contabilizar",
        id: 2,
        type: "documentDate-receptionDate",
        key: "retention"
    },
    {
        name: "Reporte Contabilizado",
        id: 3,
        type: "documentDate-receptionDate",
        key: "accounting"
    },
    {
        name: "Reporte reclamo de montos",
        id: 4,
        type: "documentDate-receptionDate",
        key: "reclaim"
    },
]

export const REPORT_CONDITIONS_IDS = [1, 5, 6]
export const RETENTION_REPORT_CONDITIONS_IDS = [2, 5, 27, 4, 24]
export const REPORT_TYPES_IDS = [1, 9, 16, 19, 5, 13, 10]