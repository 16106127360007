import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { FIELDS } from 'lib'
import { DOCUMENTS_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { AUXILIAR_PATH, PATH_MASS, PATH_RETENTION, PATH_UPLOAD, REPORT_PATH, UPLOAD_PATH } from '../../constants/paths'

const intance = axios.create({ baseURL: DOCUMENTS_API_SERVICE_DOMAIN })

const formDataConfig: AxiosRequestConfig = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}

export const uploadExcelFile = async (file: any) => {
    const formData = new FormData()
    formData.append(FIELDS.file.key, file)
    try {
        const result = await intance.post(PATH_RETENTION + PATH_MASS + PATH_UPLOAD, formData, formDataConfig)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}