import { Button, CircularProgress, Divider, FormControlLabel, FormGroup, Switch, TextField, Typography, Icon, IconButton } from '@material-ui/core'
import { useValues } from 'hooks'
import { createUserService, editUserService, FIELDS, EDIT_PATH } from 'lib'
import React from 'react'
import { useLocation, useHistory } from 'react-router'
import { IUser } from 'types'
import { IdentificationInput } from 'components'

interface Props {
    onSuccess: () => void
    onError: () => void
    onClose: () => void
}

const UserForm = (props: Props) => {
    const location = useLocation()
    const history = useHistory()
    const { onError, onSuccess, onClose } = props
    const initialInput = React.useRef<any>(null)
    const [loading, setLoading] = React.useState<boolean>(false)
    const { values: user, updateValue: handleChange, updateValues } = useValues()
    const handleSumbit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            setLoading(true)
            if (!(location.state as any)?.edit) {
                await createUserService({ ...user, active: true })
            } else {
                const edited = { ...user }
                delete edited[FIELDS.userId.key]
                await editUserService(user[FIELDS.userId.key], { ...edited })
            }
            setLoading(false)
            if (!(location.state as any)?.edit) {
                updateValues({
                    name: "", email: "", password: "", identification: "",
                    validUntil: new Date(), active: true
                } as IUser)
                initialInput.current?.focus()
            }
            onSuccess()
        } catch (error) {
            setLoading(false)
            onError()
        }
    }
    React.useEffect(() => {
        if ((location.state as any)?.edit) {
            if ((location.state as any)?.values) {
                updateValues((location.state as any)?.values)
            }
        }
        else {
            history.replace(location.pathname.replace(EDIT_PATH, ""))
        }
    }, [location.state, updateValues, history, location.pathname])
    return (
        <div className="p-4">
            <div className="flex items-center justify-between">
                <Typography variant="body2" style={{ fontWeight: 600, letterSpacing: 0.5 }}>
                    {(location.state as any)?.edit ? "Editar Usuario" : "Nuevo Usuario"}
                </Typography>
                <IconButton onClick={onClose} size="small">
                    <Icon fontSize="small">close</Icon>
                </IconButton>
            </div>
            <form onSubmit={handleSumbit} className="pt-4 flex flex-col" style={{ width: 300 }}>
                <TextField
                    inputRef={initialInput}
                    autoFocus
                    size="small"
                    label={FIELDS.name.name}
                    variant="outlined"
                    fullWidth
                    required
                    value={user[FIELDS.name.key]}
                    onChange={(e) => handleChange(FIELDS.name.key, e.target.value)}
                />
                <TextField
                    size="small"
                    label={FIELDS.email.name}
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: 10 }}
                    required
                    value={user[FIELDS.email.key]}
                    onChange={(e) => handleChange(FIELDS.email.key, e.target.value)}
                />
                {
                    !(location.state as any)?.edit &&
                    <TextField
                        size="small"
                        label={FIELDS.password.name}
                        variant="outlined"
                        type="password"
                        fullWidth
                        style={{ marginTop: 10 }}
                        required
                        value={user[FIELDS.password.key]}
                        onChange={(e) => handleChange(FIELDS.password.key, e.target.value)}
                    />
                }
                <TextField
                    size="small"
                    label={FIELDS.identification.name}
                    InputProps={{
                        inputComponent: IdentificationInput as any,
                    }}
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: 10 }}
                    value={user[FIELDS.identification.key]}
                    onChange={(e) => handleChange(FIELDS.identification.key, e.target.value)}
                />
                {
                    (location.state as any)?.edit &&
                    <FormGroup className="p-2" row>
                        <FormControlLabel
                            control={<Switch color="primary" size="small" checked={Boolean(user[FIELDS.active.key])} onChange={(e) => handleChange(FIELDS.active.key, e.target.checked)} name={FIELDS.active.name} />}
                            label={FIELDS.activeAlt.name}
                        />
                    </FormGroup>
                }
                <Typography className={`${(location.state as any)?.edit ? "pt-2" : ""} pl-1`} variant="caption" color="textSecondary">
                    {FIELDS.documentActions.name}
                </Typography>
                <FormGroup className="p-2">
                    <FormControlLabel
                        control={<Switch color="primary" size="small" checked={Boolean(user[FIELDS.canApprove.key])} onChange={(e) => handleChange(FIELDS.canApprove.key, e.target.checked)} name={FIELDS.canApprove.name} />}
                        label={FIELDS.canApprove.name}
                    />
                    <FormControlLabel
                        control={<Switch color="primary" size="small" checked={Boolean(user[FIELDS.canReject.key])} onChange={(e) => handleChange(FIELDS.canReject.key, e.target.checked)} name={FIELDS.canReject.name} />}
                        label={FIELDS.canReject.name}
                    />
                </FormGroup>                
                <div className="mt-4">
                    <Divider />
                </div>
                <div className="flex justify-end pt-3">
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        disableElevation
                        disabled={loading}
                        type="submit"
                    >
                        {loading ? <CircularProgress style={{ color: "white" }} size={16} className="my-1" thickness={10} /> : (location.state as any)?.edit ? "Guardar" : "Registrar"}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default UserForm