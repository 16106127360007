import React from "react"
import { Button, Paper, Snackbar } from "@material-ui/core"
import { FileUploadDialog, FixedLoadingIndicator } from "components"
import { AUXILIAR_TITLE, ROOT_TITLE, uploadAuxiliar } from "lib"
import { Helmet } from "react-helmet"
import { IMessageConfig } from "types"
import { Alert } from "@material-ui/lab"
import { uploadExcelFile } from "lib/utils/api/excelfile"

const ExcelRetentionPage = () => {
    console.log('Entro aca');
    const [openDialog, setOpenDialog] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })

    const handleUpload = async (file: File) => {
        try {
            setLoading(true)
            await uploadExcelFile(file)
            setLoading(false)
            setOpenDialog(false)
            setMessageConfig({ open: true, message: "El archivo cargado será procesado a continuación.", severity: "info" })
        } catch (error) {
            setLoading(false)
            setOpenDialog(false)
            setMessageConfig({ open: true, message: "El archivo cargado no pudo ser procesado.", severity: "error" })
            console.log(error)
        }
    }

    return (
        <Paper className="flex flex-col h-full overflow-hidden p-4 pb-0">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${AUXILIAR_TITLE}`}</title>
            </Helmet>
            <div className="flex items-center justify-between">
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => {
                        setOpenDialog(true)
                    }}
                >
                    {"Subir Archivo Excel"}
                </Button>
            </div>
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <FixedLoadingIndicator loading={loading} />
            <FileUploadDialog
                open={openDialog}
                onClose={() => {setOpenDialog(false)}}
                onAccept={(files) => {handleUpload(files[0].file)}}
                singleFile
                hideDescription
            />
        </Paper>
    )
}

export default ExcelRetentionPage