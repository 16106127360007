import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { FIELDS, formatCurrency } from 'lib'
import React from 'react'
import { IDetail } from 'types'

import 'moment/locale/es'
import moment from 'moment'

moment.locale("es")

interface Props {
    detail: IDetail[]
    hideDiscount?: boolean
    hideTotal?: boolean
    hideUnitPrice?: boolean
    specialDetail?: boolean
}

const headerCellStyle = { fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2 }
const bodyCellStyle = { fontSize: "0.8em", letterSpacing: 0.2 }
const getTotalByUnitPriceAndTax = (unitPrice: number, quantity: number) => {
    const totalNoTaxes = (unitPrice * quantity)
    return totalNoTaxes
}

const DetailData = (props: Props) => {
    const { detail, hideDiscount, hideTotal, hideUnitPrice, specialDetail } = props
    return (
        <React.Fragment>
            {
                detail.length > 0 &&
                <Paper variant="outlined" className={`mt-3 w-full ${(!hideDiscount && !hideTotal && !hideUnitPrice) ? "mx-8" : ""}`} elevation={1}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                {
                                    !specialDetail ?
                                        <TableRow>
                                            {
                                                (detail[0][FIELDS.purchaseCode.key] !== undefined && detail[0][FIELDS.purchaseCode.key] !== null) &&
                                                <TableCell style={headerCellStyle} align="left">{FIELDS.purchaseCode.name}</TableCell>
                                            }
                                            {
                                                (detail[0][FIELDS.description.key] !== undefined && detail[0][FIELDS.description.key] !== null) &&
                                                <TableCell style={headerCellStyle} align="left">{FIELDS.description.name}</TableCell>
                                            }
                                            {
                                                (detail[0][FIELDS.quantity.key] !== undefined && detail[0][FIELDS.quantity.key] !== null) &&
                                                <TableCell style={headerCellStyle} align="right">{FIELDS.quantity.name}</TableCell>
                                            }
                                            {
                                                (detail[0][FIELDS.unitPrice.key] !== undefined && detail[0][FIELDS.unitPrice.key] !== null && !hideUnitPrice) &&
                                                <TableCell style={headerCellStyle} align="right">{FIELDS.unitPrice.name}</TableCell>
                                            }
                                            {
                                                (detail[0][FIELDS.discount.key] !== undefined && detail[0][FIELDS.unitPrice.key] !== null && !hideDiscount) &&
                                                <TableCell style={headerCellStyle} align="right">{FIELDS.discount.name}</TableCell>
                                            }
                                            {
                                                !hideTotal &&
                                                <TableCell style={headerCellStyle} align="right">{"Total"}</TableCell>
                                            }
                                        </TableRow>
                                        :
                                        <TableRow>
                                            <TableCell style={headerCellStyle} align="left">{FIELDS.voucher.name}</TableCell>
                                            <TableCell style={headerCellStyle} align="left">{FIELDS.documentNumber.name}</TableCell>
                                            <TableCell style={headerCellStyle} align="left">{FIELDS.emissionDate.name}</TableCell>
                                            <TableCell style={headerCellStyle} align="left">{FIELDS.fiscalExercise.name}</TableCell>
                                            <TableCell style={headerCellStyle} align="left">{FIELDS.tax.name}</TableCell>
                                            <TableCell style={headerCellStyle} align="right">{FIELDS.taxBase.name}</TableCell>
                                            <TableCell style={headerCellStyle} align="right">{FIELDS.retainedPercentage.name}</TableCell>
                                            <TableCell style={headerCellStyle} align="right">{FIELDS.retainedValue.name}</TableCell>
                                        </TableRow>
                                }
                            </TableHead>
                            <TableBody>
                                {
                                    detail.map(row => (
                                        <React.Fragment>
                                            {
                                                !specialDetail ?
                                                    <TableRow>
                                                        {
                                                            (row[FIELDS.purchaseCode.key] !== undefined && row[FIELDS.purchaseCode.key] !== null) &&
                                                            <TableCell style={headerCellStyle} align="left">{row[FIELDS.purchaseCode.key]}</TableCell>
                                                        }
                                                        {
                                                            (row[FIELDS.description.key] !== undefined && row[FIELDS.description.key] !== null) &&
                                                            <TableCell style={bodyCellStyle} align="left">{row[FIELDS.description.key]}</TableCell>
                                                        }
                                                        {
                                                            (row[FIELDS.quantity.key] !== undefined && row[FIELDS.quantity.key] !== null) &&
                                                            <TableCell style={bodyCellStyle} align="right">{row[FIELDS.quantity.key]}</TableCell>
                                                        }
                                                        {
                                                            (row[FIELDS.unitPrice.key] !== undefined && row[FIELDS.unitPrice.key] !== null && Boolean(row[FIELDS.tax.key]) && !hideUnitPrice) &&
                                                            <TableCell style={bodyCellStyle} align="right">{formatCurrency(parseFloat(row[FIELDS.unitPrice.key]))}</TableCell>
                                                        }
                                                        {
                                                            (!hideDiscount) &&
                                                            <TableCell style={bodyCellStyle} align="right">{row[FIELDS.discount.key] === null || row[FIELDS.discount.key] === undefined ? formatCurrency(0) : `${formatCurrency(parseFloat(row[FIELDS.discount.key]) * (row[FIELDS.quantity.key] * parseFloat(row[FIELDS.unitPrice.key])))}`}</TableCell>
                                                        }
                                                        {
                                                            !hideTotal &&
                                                            <TableCell style={bodyCellStyle} align="right">
                                                                {row.total ? formatCurrency(row.total) : formatCurrency((row[FIELDS.unitPrice.key] !== undefined && row[FIELDS.unitPrice.key] !== null && Boolean(row[FIELDS.tax.key])) ? getTotalByUnitPriceAndTax(parseFloat(row[FIELDS.unitPrice.key]), parseInt(row[FIELDS.quantity.key])) : (parseFloat(row[FIELDS.subtotalNoTaxes.key]) + parseFloat(row[FIELDS.taxesValue.key])))}
                                                            </TableCell>
                                                        }
                                                    </TableRow>
                                                    :
                                                    <TableRow>
                                                        <TableCell style={bodyCellStyle} align="left">{row.documentType}</TableCell>
                                                        <TableCell style={bodyCellStyle} align="left">{row.supportDocumentNumber}</TableCell>
                                                        <TableCell style={bodyCellStyle} align="left">{moment(row?.supportEmitionDate?.toISOString())?.format('DD-MM-YYYY')?.toString()}</TableCell>
                                                        <TableCell style={bodyCellStyle} align="left">{moment(row?.supportEmitionDate?.toISOString())?.format('MM-YYYY')?.toString()}</TableCell>
                                                        <TableCell style={bodyCellStyle} align="left">{row.taxAndTaxType}</TableCell>
                                                        <TableCell style={bodyCellStyle} align="right">{formatCurrency(row.taxBase ?? 0)}</TableCell>
                                                        <TableCell style={bodyCellStyle} align="right">{formatCurrency(row.retainedPercentage ?? 0)}</TableCell>
                                                        <TableCell style={bodyCellStyle} align="right">{formatCurrency(row.retainedValue ?? 0)}</TableCell>
                                                    </TableRow>
                                            }
                                        </React.Fragment>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            }
        </React.Fragment >
    )
}

export default DetailData