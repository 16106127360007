import { ISupportDocument } from "types";

export const SUPPORT_DOCUMENTS_OPTIONS: ISupportDocument[] = [
  {
    code: "01",
    name: "Factura",
  },
  {
    code: "02",
    name: "Nota o boleta de Venta",
  },
  {
    code: "04",
    name: "Nota de Crédito",
  },
  {
    code: "05",
    name: "Nota de Crédito",
  },
  {
    code: "08",
    name: "Boletos o entradas a espectaculos públicos",
  },
  {
    code: "09",
    name: "Tiquetes o vales emitidos por maquinas registradoras",
  },
  {
    code: "11",
    name: "Pasajes expedidos por empresas de aviación",
  },
  {
    code: "12",
    name: "Documentos emitidos por instituciones financieras",
  },
  {
    code: "19",
    name: "Comprobantes de pago de Cuotas o Aportes",
  },
  {
    code: "20",
    name: "Documentos por Servicios Adminsitrativos emitidos por Inst. del Estado",
  },
  {
    code: "21",
    name: "Carta de Porte Aéreo",
  },
  {
    code: "41",
    name: "Comprobante de venta emitido por reembolso",
  },
  {
    code: "42",
    name: "Documento retención presuntiva y retención emitida por propio vendedor o por intermediario",
  },
  {
    code: "43",
    name: "Liquidacion para Explotación y Exploración de Hidrocarburos",
  },
  {
    code: "45",
    name: "Liquidacion por reclamos de aseguradoras",
  },
  {
    code: "47",
    name: "Nota de Crédito por reembolso emitida por intermediario",
  },
  {
    code: "48",
    name: "Nota de Débito por reembolso emitida por intermediario",
  },
  {
    code: "364",
    name: "Acta Entrega - Recepcion PET",
  },
];

