import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { CONFIGURATION_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { FIELDS } from 'lib/constants/fields'
import { LIST_PATH, SUPPLIER_PATH, SUPPLIER_FILE_PATH, IDENTIFICATION_PATH } from '../../constants/paths'

const intance = axios.create({ baseURL: CONFIGURATION_API_SERVICE_DOMAIN })
const formDataConfig: AxiosRequestConfig = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}

export const getSuppliersService = async () => {
    try {
        const result = await intance.get(SUPPLIER_PATH + LIST_PATH)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const getSupplierByIdService = async (id: any) => {
    try {
        const result = await intance.get(SUPPLIER_PATH + IDENTIFICATION_PATH + `/${id}`)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const createSupplierService = async (Supplier: any) => {
    try {
        const result = await intance.post(SUPPLIER_PATH, Supplier)
        return result.data
    } catch (error: any) {
        throw error as AxiosError
    }
}

export const deleteSupplierService = async (id: any) => {
    try {
        const result = await intance.delete(SUPPLIER_PATH + `/${id}`)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const editSupplierService = async (id: number, supplier: any) => {
    try {
        const result = await intance.put(SUPPLIER_PATH + `/${id}`, supplier)
        return result.data
    } catch (error: any) {
        throw error as AxiosError
    }
}

export const uploadSupplierFileService = async (id: number, tag: string, file: File) => {
    const formData = new FormData()
    formData.append(FIELDS.supplierId.key, id.toString())
    formData.append(FIELDS.tag.key, tag)
    formData.append(FIELDS.file.key, file)
    try {
        const result = await intance.post(SUPPLIER_FILE_PATH, formData, formDataConfig)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const getSuppliersFileService = async (id: number) => {
    try {
        const result = await intance.get(SUPPLIER_FILE_PATH + LIST_PATH + `/${id}`)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const getSupplierFileByIdService = async (id: number) => {
    try {
        const result = await intance.get(SUPPLIER_FILE_PATH + `/${id}`, { responseType: "blob" })
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const deleteSupplierFileByIdService = async (id: number) => {
    try {
        const result = await intance.delete(SUPPLIER_FILE_PATH + `/${id}`)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}
