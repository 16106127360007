import React from 'react'
import { Button, Icon, IconButton, Menu, MenuItem, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@material-ui/core'
import { ConfirmDialog, CustomerForm, Search, FullSizeDialog, FixedLoadingIndicator, LabelDisplayedRows, ServerErrorHandler } from 'components'
import { CUSTOMER_PATH, NEW_PATH, RECEIVED_PATH, CUSTOMER_TABLE_COLUMNS, EDIT_PATH, deleteCustomerService, SMALL_ROWS_PER_PAGINATION_OPTIONS, ROOT_TITLE, CUSTOMER_TITLE } from 'lib'
import { useHistory, useLocation } from 'react-router'
import { IMessageConfig, ICustomer } from 'types'
import { ManagementContext } from 'context'
import { Alert } from '@material-ui/lab'
import { Helmet } from "react-helmet"

const Customers = () => {
    const { loading, customers, customersTotal, customersFilter, customersModel, loadInitialCustomers, loadInitialCustomersWithFilter, refreshCustomers, setCustomersFilter, setContextLoading } = React.useContext(ManagementContext)
    const { createSortHandler, handleChangePage, handleChangeRowsPerPage, order, orderBy, page, rows, rowsPerPage } = customersModel
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false)
    const location = useLocation()
    const history = useHistory()
    const [selected, setSelected] = React.useState<any | undefined>(undefined)
    const [actionsAnchorEl, setActionsAnchorEl] = React.useState<null | HTMLElement>(null)
    const [deleteId, setDeleteId] = React.useState<number>(-1)
    const [error, setError] = React.useState<boolean>(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const getModel = React.useCallback((): any[] => { return (initialLoading ? [] : customers) }, [customers, initialLoading])
    const handleEdit = () => {
        setActionsAnchorEl(null)
        history.push({
            state: {
                edit: true,
                values: selected
            },
            pathname: `${RECEIVED_PATH}${CUSTOMER_PATH}${EDIT_PATH}`
        })
    }
    const handleConfirmDelete = (id: number) => {
        setActionsAnchorEl(null)
        setDeleteId(id)
    }
    const handleDelete = async () => {
        try {
            setContextLoading(true)
            await deleteCustomerService(deleteId)
            setMessageConfig({ open: true, message: "Cliente eliminado!", severity: "success" })
            await loadInitialCustomers()
            setContextLoading(false)
            return true
        } catch (error) {
            setMessageConfig({ open: true, message: "No se pudo eliminar el cliente...!", severity: "error" })
            setContextLoading(false)
            return false
        }
    }
    const handleFilter = async () => {
        try {
            setContextLoading(true)
            await loadInitialCustomersWithFilter()
            handleChangePage(undefined, 0)
            setContextLoading(false)
        } catch (error) {
            setContextLoading(false)
            setError(true)
        }
    }
    const handleClear = async () => {
        try {
            setContextLoading(true)
            setCustomersFilter("")
            await loadInitialCustomers()
            handleChangePage(undefined, 0)
            setContextLoading(false)
        } catch (error) {
            setContextLoading(false)
            setError(true)
        }
    }
    const handleNewPage = async (page: number, pageSize: number) => {
        try {
            setContextLoading(true)
            await refreshCustomers(page, pageSize)
            setContextLoading(false)
        } catch (error) {
            setContextLoading(false)
            setError(true)
        }
    }

    React.useEffect(() => {
        const initScreen = async () => {
            try {
                setContextLoading(true)
                setInitialLoading(true)
                await loadInitialCustomers()
                handleChangePage(undefined, 0)
                setCustomersFilter("")
                setContextLoading(false)
                setInitialLoading(false)
            } catch (error) {
                setContextLoading(false)
                setError(true)
            }
        }
        initScreen()
    }, [loadInitialCustomers, setContextLoading, setInitialLoading, setCustomersFilter, handleChangePage])

    return (
        <Paper className="flex flex-col h-full overflow-hidden p-4 pb-0">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${CUSTOMER_TITLE}`}</title>
            </Helmet>
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <Search
                        onChange={(e) => setCustomersFilter(e.target.value)}
                        query={customersFilter}
                        onClear={handleClear}
                        placeholer={"Buscar por razón social..."}
                    />
                </div>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={handleFilter}
                >
                    {"Buscar"}
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => history.push(`${RECEIVED_PATH}${CUSTOMER_PATH}${NEW_PATH}`)}
                >
                    <Icon fontSize="small" style={{ marginRight: 5 }}>add</Icon>
                    {"Nuevo"}
                </Button>
                <FullSizeDialog
                    open={Boolean(location.pathname === `${RECEIVED_PATH}${CUSTOMER_PATH}${NEW_PATH}` || location.pathname === `${RECEIVED_PATH}${CUSTOMER_PATH}${EDIT_PATH}`)}
                    onClose={() => history.push(`${RECEIVED_PATH}${CUSTOMER_PATH}`)}
                >
                    <CustomerForm
                        onSuccess={() => { setMessageConfig({ open: true, message: Boolean(selected) ? "Cliente actualizado!" : "Cliente registrado!", severity: "success" }); loadInitialCustomers(); if (Boolean(selected)) { setSelected(undefined) } }}
                        onError={(message?: string) => setMessageConfig({ open: true, message: message ?? "No se pudo registrar el cliente", severity: "error" })}
                        onClose={() => history.push(`${RECEIVED_PATH}${CUSTOMER_PATH}`)}
                    />
                </FullSizeDialog>
            </div>
            <div className="flex h-full flex-col mt-4 overflow-hidden">
                <div className="flex flex-grow overflow-auto">
                    <TableContainer>
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    {CUSTOMER_TABLE_COLUMNS.map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            align={'left'}
                                            padding={"none"}
                                            sortDirection={orderBy === headCell.id ? order : false}
                                        >
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={createSortHandler(headCell.id as any)}
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <span className="hidden">
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                    <TableCell
                                        padding={"default"}
                                    />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rows(getModel()) as ICustomer[]).map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.customerId.toString()}
                                        >
                                            <TableCell component="th" scope="row" padding="none">{row.identification}</TableCell>
                                            <TableCell component="th" scope="row" padding="none">{row.identificationType?.name}</TableCell>
                                            <TableCell component="th" scope="row" padding="none">{row.commercialName}</TableCell>
                                            <TableCell component="th" scope="row" padding="none">{row.specialTaxpayer}</TableCell>
                                            <TableCell padding="none" align="left"><span className={row.active ? "active-state-label" : "inactive-state-label"}>{row.active ? "Activo" : "Inactivo"}</span></TableCell>
                                            <TableCell align="left">
                                                <IconButton
                                                    onClick={(e) => {
                                                        setActionsAnchorEl(e.currentTarget)
                                                        setSelected(row)
                                                    }}
                                                    size="small"
                                                >
                                                    <Icon fontSize="small">more_vert</Icon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                                <Menu
                                    anchorEl={actionsAnchorEl}
                                    open={Boolean(actionsAnchorEl)}
                                    onClose={() => setActionsAnchorEl(null)}
                                >
                                    <MenuItem onClick={handleEdit} dense>{"Editar"}</MenuItem>
                                    <MenuItem onClick={() => handleConfirmDelete(selected?.customerId ?? -1)} dense>{"Eliminar"}</MenuItem>
                                </Menu>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div>
                    <TablePagination
                        rowsPerPageOptions={SMALL_ROWS_PER_PAGINATION_OPTIONS}
                        component="div"
                        count={customersTotal}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={async (event, pageNumber) => {
                            if (pageNumber > page && ((pageNumber + 1) * rowsPerPage) > getModel().length && getModel().length < customersTotal) {
                                await handleNewPage(pageNumber, rowsPerPage)
                            }
                            handleChangePage(event, pageNumber)
                        }}
                        onRowsPerPageChange={async (event) => {
                            if (parseInt(event.target.value, 10) > rowsPerPage && parseInt(event.target.value, 10) > getModel().length && getModel().length < customersTotal) {
                                await handleNewPage(page, parseInt(event.target.value, 10))
                            }
                            handleChangeRowsPerPage(event as any)
                        }}
                        labelRowsPerPage="Clientes por página"
                        labelDisplayedRows={LabelDisplayedRows}
                    />
                </div>
            </div>
            <ConfirmDialog
                onCancel={() => setDeleteId(-1)}
                onConfirm={handleDelete}
                open={Boolean(deleteId !== -1)}
                title={"¿Estás seguro que deseas eliminar?"}
                loading={loading}
            />
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <FixedLoadingIndicator loading={loading && Boolean(deleteId === -1)} />
            <ServerErrorHandler
                error={error}
                onSuccess={() => setError(false)}
                tryAgain={loadInitialCustomers}
            />
        </Paper>
    )
}


export default Customers
