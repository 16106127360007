import { Paper, Typography, Divider, TableContainer, Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core'
import { IDocumentEvent } from 'types'
import moment from 'moment'
import 'moment/locale/es'

moment.locale("es")

interface Props {
    events: IDocumentEvent[]
}

const headerCellStyle = { fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2 }
const bodyCellStyle = { fontSize: "0.8em", letterSpacing: 0.2 }

const DocumentEvents = (props: Props) => {
    const { events } = props
    return (
        <div className="px-8 w-full">
            <Paper variant="outlined" className="mt-3 w-full" elevation={1}>
                <div className="py-3 px-4 flex w-full items-center">
                    <Typography style={{ fontWeight: 600, letterSpacing: 0.5, color: "#b1b1b1" }} variant="caption">
                        {"EVENTOS"}
                    </Typography>
                </div>
                <Divider />
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={headerCellStyle} align="left">{"Evento"}</TableCell>
                                <TableCell style={headerCellStyle} align="left">{"Descripción"}</TableCell>
                                <TableCell style={headerCellStyle} align="left">{"Usuario"}</TableCell>
                                <TableCell style={headerCellStyle} align="left">{"Fecha"}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                events.map(event => (
                                    <TableRow>
                                        <TableCell style={bodyCellStyle} align="left">{event.historyName}</TableCell>
                                        <TableCell style={bodyCellStyle} align="left">{event.historyDescription}</TableCell>
                                        <TableCell style={bodyCellStyle} align="left">{event.userName}</TableCell>
                                        <TableCell style={bodyCellStyle} align="left">{moment(new Date(event.inserted).toISOString()).format('DD-MM-YYYY').toString()}</TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default DocumentEvents
