import React from 'react'
import { ITableModelHook, useTableModel } from 'hooks'
import { DEFAULT_DOCUMENT_FILTERS, DOCUMENT_STATES, DOCUMENT_TYPE_CODES, getCompanyService, getConditionsService, getCostCentersService, getDocumentsService, getDocumentTypesService, getHistoryTypesService, getPurchaseOrdersService, getReimbursementTaxPlan, transformDocumentFilters } from 'lib'
import { ICompany, ICondition, ICostCenter, IDashboardCount, IDocument, IDocumentFilter, IDocumentType, IHistoryType, IPurchaseOrder, ITaxPlan } from 'types'

interface Props {
    documentTypes: IDocumentType[]
    historyTypes: IHistoryType[]
    documents: IDocument[]
    purchaseOrders: IPurchaseOrder[]
    conditions: ICondition[]
    documentFilters: IDocumentFilter
    documentsModel: ITableModelHook
    totalDocuments: number
    company?: ICompany
    costCenters: ICostCenter[]
    reimbursementTaxPlan: ITaxPlan
    count: IDashboardCount
    totalPurchaseOrders: number
    refreshDocuments: () => Promise<any>
    getDocumentsByFilters: () => Promise<any>
    getNextDocumentsByFilters: (pageSize: number, offset: number) => Promise<any>
    getNextPurchaseOrderByFilters: (userId: number, pageSize: number, offset: number, initialCondition?: string) => Promise<any>
    getDocumentsByInitialFilters: (initialCode?: string, initialState?: number, initialCompany?: string, initialHistoryType?: number, receptor?: string) => Promise<any>
    getPurchaseOrdersByInitialCondition: (userId: number, initialCode?: string) => Promise<any>
    setFilters: React.Dispatch<IDocumentFilter>
    refreshConditions: () => Promise<void>
}

export const DocumentContext = React.createContext({} as Props)

const DocumentProvider: React.FC = ({ children }) => {
    const [company, setCompany] = React.useState<ICompany | undefined>()
    const [costCenters, setCostCenters] = React.useState<ICostCenter[]>([])
    const [documents, setDocuments] = React.useState<IDocument[]>([])
    const [purchaseOrders, setPurchaseOrders] = React.useState<IPurchaseOrder[]>([])
    const [documentTypes, setDocumentTypes] = React.useState<IDocumentType[]>([])
    const [historyTypes, setHistoryTypes] = React.useState<IHistoryType[]>([])
    const [conditions, setConditions] = React.useState<ICondition[]>([])
    const [totalDocuments, setTotalDocuments] = React.useState<number>(0)
    const [totalPurchaseOrders, setTotalPurchaseOrders] = React.useState<number>(0)
    const [reimbursementTaxPlan, setReimbursementTaxPlan] = React.useState({} as ITaxPlan)
    const [documentFilters, setFilters] = React.useState<IDocumentFilter>({ ...DEFAULT_DOCUMENT_FILTERS })
    const { createSortHandler, handleChangePage, handleChangeRowsPerPage, order, orderBy, page, rows, rowsPerPage } = useTableModel()
    const [count, setCount] = React.useState<IDashboardCount>({ completed: 0, entered: 0, pending: 0, rejected: 0, error: 0, unsend: 0, total: 0 })
    const refreshDocuments = React.useCallback(async () => {
        try {
            const response = await getDocumentsService(transformDocumentFilters(DEFAULT_DOCUMENT_FILTERS), 25, 0)
            const documentsResponse = (response.documents as IDocument[]).map(d => ({ ...d, additionalInfo: JSON.parse(((d.additionalInfo as unknown) as string)) }))
            setDocuments(documentsResponse)
            setCount(response.count)
            setTotalDocuments(response.count.total)
        } catch (error) {
            throw new Error()
        }
    }, [])
    const getDocumentsByFilters = React.useCallback(async () => {
        try {
            const response = await getDocumentsService(transformDocumentFilters(documentFilters), 25, 0)
            const documentsResponse = (response.documents as IDocument[]).map(d => ({ ...d, additionalInfo: JSON.parse(((d.additionalInfo as unknown) as string)) }))
            setDocuments(documentsResponse)
            setCount(response.count)
            setTotalDocuments(response.count.total)
        } catch (error) {
            throw new Error()
        }
    }, [documentFilters])
    const getNextDocumentsByFilters = React.useCallback(async (pageSize: number, offset: number) => {
        try {
            const response = await getDocumentsService(transformDocumentFilters(documentFilters), pageSize, offset)
            const documentsResponse = (response.documents as IDocument[]).map(d => ({ ...d, additionalInfo: JSON.parse(((d.additionalInfo as unknown) as string)) }))
            setDocuments(current => current.concat(documentsResponse))
            setCount(response.count)
            setTotalDocuments(response.count.total)
        } catch (error) {
            throw new Error()
        }
    }, [documentFilters])
    const getDocumentsByInitialFilters = React.useCallback(async (initialCode?: string, initialState?: number, initialCompany?: string, initialHistoryType?: number, receiverCompany?: string) => {
        const type = documentTypes.find(d => d.documentCode === initialCode)
        const condition = conditions.find(c => c.conditionId === initialState)
        const historyType = historyTypes.find(h => h.historyTypeId === initialHistoryType)
        const initialConditions = condition ? [{ ...condition }] : []
        let initialTypes = type ? [{ ...type }] : []
        const initialHistoryTypes = historyType ? [{ ...historyType }] : []

        if (initialState === DOCUMENT_STATES.entered) {
            const aditionalCondition = conditions.find(c => c.conditionId === DOCUMENT_STATES.sriEntered)
            if (aditionalCondition) { initialConditions.push(aditionalCondition) }
        }

        if (initialState === DOCUMENT_STATES.rejected) {
            const aditionalCondition = conditions.find(c => c.conditionId === DOCUMENT_STATES.rejectedCOUPA)
            if (aditionalCondition) { initialConditions.push(aditionalCondition) }
            const aditionalCondition2 = conditions.find(c => c.conditionId === DOCUMENT_STATES.rejectedSAT)
            if (aditionalCondition2) { initialConditions.push(aditionalCondition2) }
        }

        if (initialState === DOCUMENT_STATES.inProcess) {
            const aditionalCondition = conditions.find(c => c.conditionId === DOCUMENT_STATES.accounted)
            if (aditionalCondition) { initialConditions.push(aditionalCondition) }
        }

        if (initialCode === DOCUMENT_TYPE_CODES.companyReception) {
            const companyReceptionTypes = documentTypes.filter(d => d.isVisible)
            initialTypes = companyReceptionTypes
        }

        if (initialCode === DOCUMENT_TYPE_CODES.creditNote) {
            const additionalType = documentTypes.find(d => d.documentCode === DOCUMENT_TYPE_CODES.printedCreditNote)
            if (additionalType) {
                initialTypes.push(additionalType)
            }
        }

        if (initialCode === DOCUMENT_TYPE_CODES.retentions) {
            const additionalType = documentTypes.filter(d => [DOCUMENT_TYPE_CODES.electronicInvoice, DOCUMENT_TYPE_CODES.creditNote].includes(d.documentCode))
            if (additionalType) {
                initialTypes = initialTypes.concat(additionalType)
            }
        }

        if (initialCode === DOCUMENT_TYPE_CODES.reimbursements) {
            const additionalType = documentTypes.filter(d => [DOCUMENT_TYPE_CODES.employeeReimbursement, DOCUMENT_TYPE_CODES.cashReimbursement].includes(d.documentCode))
            if (additionalType) {
                initialTypes = initialTypes.concat(additionalType)
            }
        }

        if (initialCode === DOCUMENT_TYPE_CODES.allRetentions) {
            const additionalType = documentTypes.filter(d => [DOCUMENT_TYPE_CODES.retentionReceipt, DOCUMENT_TYPE_CODES.printedRetention].includes(d.documentCode))
            if (additionalType) {
                initialTypes = initialTypes.concat(additionalType)
            }
        }

        try {
            setCount({ completed: 0, entered: 0, pending: 0, rejected: 0, total: 0, error: 0, unsend: 0 })
            const response = await getDocumentsService(transformDocumentFilters({
                ...DEFAULT_DOCUMENT_FILTERS,
                documentTypes: initialTypes,
                conditions: initialConditions,
                historyTypes: initialHistoryTypes,
                rucOrName: initialCompany ?? "",
                rucOrNameReceptor: receiverCompany ?? ""
            }), 25, 0)
            const documentsResponse = (response.documents as IDocument[]).map(d => ({ ...d, additionalInfo: JSON.parse(((d.additionalInfo as unknown) as string)) }))
            setDocuments(documentsResponse)
            setCount(response.count)
            setTotalDocuments(response.count.total)
            setFilters({
                documentTypes: initialTypes,
                conditions: initialConditions,
                rucOrName: initialCompany ?? "",
                documentRange: { end: null, start: null },
                receptionRange: { end: null, start: null },
                serialNumber: "",
                historyTypes: initialHistoryTypes,
                rucOrNameReceptor: receiverCompany ?? ""
            })
        } catch (error) {
            console.log(error)
        }
    }, [documentTypes, conditions, historyTypes])

    const getPurchaseOrdersByInitialCondition = React.useCallback(async (userId: number, initialCondition?: string, ) => {
        try {
            const response = await getPurchaseOrdersService({ conditions: initialCondition ?? "" }, 25, 0, userId)
            setPurchaseOrders(response.documents)
            setTotalPurchaseOrders(response.count)
        } catch (error) {
            console.log(error)
        }
    }, [])

    const getNextPurchaseOrderByFilters = React.useCallback(async (userId: number, pageSize: number, offset: number, initialCondition?: string) => {
        try {
            const response = await getPurchaseOrdersService({ conditions: initialCondition ?? "" }, pageSize, offset, userId)
            setPurchaseOrders(current => current.concat(response.documents))
            setTotalPurchaseOrders(response.count)
        } catch (error) {
            console.log(error)
        }
    }, [])

    const refreshConditions = React.useCallback(async () => {
        try {
            setConditions(await getConditionsService())
        } catch (error) {
            console.log(JSON.stringify(error))
        }
    }, [])
    React.useEffect(() => {
        const initContext = async () => {
            try {
                setDocumentTypes(await getDocumentTypesService())
                setCompany(await getCompanyService() ?? undefined)
                setConditions(await getConditionsService())
                setCostCenters(await getCostCentersService())
                setHistoryTypes(await getHistoryTypesService())
                setReimbursementTaxPlan(await getReimbursementTaxPlan())
            } catch (error) {
                console.log(error)
            }
        }
        initContext()
    }, [])
    return (
        <DocumentContext.Provider
            value={{
                documentTypes,
                historyTypes,
                documents,
                purchaseOrders,
                documentFilters,
                conditions,
                totalDocuments,
                reimbursementTaxPlan,
                company,
                costCenters,
                count,
                documentsModel: { createSortHandler, handleChangePage, handleChangeRowsPerPage, order, orderBy, page, rows, rowsPerPage },
                totalPurchaseOrders,
                refreshDocuments,
                setFilters,
                getDocumentsByInitialFilters,
                getDocumentsByFilters,
                getNextDocumentsByFilters,
                refreshConditions,
                getPurchaseOrdersByInitialCondition,
                getNextPurchaseOrderByFilters
            }}
        >
            {children}
        </DocumentContext.Provider>
    )
}

export default DocumentProvider
