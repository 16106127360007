import React from "react"
import {
    RESOURCES_PATH, OFFICE_PATH, ACCOUNT_TYPE_PATH, TAX_PATH, TAX_TYPE_PATH,
    TAX_PLAN_PATH, REJECTION_CAUSE_PATH, SUPPLIER_PATH,
    MATRIX_PATH, IDENTIFICATION_TYPE_PATH
} from "lib"
import {
    Offices, AccountTypes, Taxes, TaxTypes, TaxPlans, RejectionCauses,
    Suppliers, Matrixes, IdentificationTypes
} from "pages"
import { Route } from "react-router-dom"
import { AuthContext } from "context"

const EmissionTaxPlans = () => <TaxPlans forCustomers={false} />

const Resources = () => {
    const { user } = React.useContext(AuthContext)
    return (
        <React.Fragment>
            {
                Boolean(user) &&
                <React.Fragment>
                    <Route strict path={`${RESOURCES_PATH}${OFFICE_PATH}`} component={Offices} />
                    <Route strict path={`${RESOURCES_PATH}${ACCOUNT_TYPE_PATH}`} component={AccountTypes} />
                    <Route strict path={`${RESOURCES_PATH}${TAX_PATH}`} component={Taxes} />
                    <Route strict path={`${RESOURCES_PATH}${TAX_TYPE_PATH}`} component={TaxTypes} />
                    <Route strict path={`${RESOURCES_PATH}${TAX_PLAN_PATH}`} component={EmissionTaxPlans} />
                    <Route strict path={`${RESOURCES_PATH}${REJECTION_CAUSE_PATH}`} component={RejectionCauses} />
                    <Route strict path={`${RESOURCES_PATH}${SUPPLIER_PATH}`} component={Suppliers} />
                    <Route strict path={`${RESOURCES_PATH}${MATRIX_PATH}`} component={Matrixes} />
                    <Route strict path={`${RESOURCES_PATH}${IDENTIFICATION_TYPE_PATH}`} component={IdentificationTypes} />
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default Resources
