import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Checkbox } from '@material-ui/core'
import { useGetCustomer, useGetSupplier } from 'hooks'
import { approveDocumentService, DOCUMENT_APPROVE_MANUALLY_ACTION_ID, DOCUMENT_MANAGE_ACTION_ID, DOCUMENT_REJECT_MANUALLY_ACTION_ID, DOCUMENT_SEND_REIMBURSEMENT_ACTION_ID, DOCUMENT_STATES, FIELDS, manageDocumentByIdService } from 'lib'
import React from 'react'
import { IDocumentAction, ISupplier } from 'types'
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import { Autocomplete } from '@material-ui/lab'
import isEmail from 'validator/lib/isEmail'
import { AuthContext } from 'context'
import { FixedLoadingIndicator } from 'components'
import moment from 'moment'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface Props {
    open: boolean
    onClose: () => void
    supplier?: ISupplier
    onSend: () => void
    onError: () => void
    invoiceNumber?: string
    noEmailsMessage: () => void
    documentId: number
    selectedAction: IDocumentAction | undefined
    documentData?: any
}

const SendMailDialog = (props: Props) => {
    const { onClose, open, onSend, onError, invoiceNumber, noEmailsMessage, documentId, selectedAction, documentData } = props
    const { supplier, loading: supplierLoading } = useGetSupplier({ ruc: props.supplier?.identification ?? "" })
    const { customer, loading: customerLoading } = useGetCustomer({ identification: documentData?.header?.supplier?.identification ?? "" })
    const { user } = React.useContext(AuthContext)
    const [emails, setEmails] = React.useState<string[]>([])
    const [message, setMessage] = React.useState<string>("")
    const [subject, setSubject] = React.useState<string>("")
    const [title, setTitle] = React.useState<string>("")
    const [reimbursementMessage, setReimbursementMessage] = React.useState<string>("")
    const [dialogLoading, setDialogLoading] = React.useState<boolean>(false)

    const handleSend = async (e: React.FormEvent) => {
        e.preventDefault()
        if (emails.length === 0) {
            noEmailsMessage()
            return
        }

        try {
            setDialogLoading(true)
            if (selectedAction?.id === DOCUMENT_APPROVE_MANUALLY_ACTION_ID) {
                await approveDocumentService(
                    {
                        documentId,
                        userId: user?.userId,
                        statusId: DOCUMENT_STATES.inProcess
                    },
                    { documentData: {} }
                )    
                await manageDocumentByIdService(documentId, emails.join(";"), subject, message); 
            } else if (selectedAction?.id === DOCUMENT_REJECT_MANUALLY_ACTION_ID) {
                await approveDocumentService(
                    {
                        documentId,
                        userId: user?.userId,
                        statusId: DOCUMENT_STATES.rejected
                    },
                    { documentData: {} }
                )  
                await manageDocumentByIdService(documentId, emails.join(";"), subject, message); 
            } else if (selectedAction?.id === DOCUMENT_MANAGE_ACTION_ID) {
                await manageDocumentByIdService(documentId, emails.join(";"), subject, message); 
            } else if (selectedAction?.id === DOCUMENT_SEND_REIMBURSEMENT_ACTION_ID) {
                await manageDocumentByIdService(documentId, emails.join(";"), subject, message); 
            }
            onSend()
            onClose()
            setDialogLoading(false)
        } catch (error) {
            onError()
            setDialogLoading(false)
        }
    }

    const getEmails = React.useCallback(() => {
        const emailsArray:string[] = []
        if (customer) {
            customer.contacts?.forEach(
                contact => {
                    if (contact.email?.trim() !== "") {
                        contact.email?.split(";").forEach(
                            email => {
                                emailsArray.push(email.trim())
                            }
                        )
                    }
                }
            )
        }
        // if (supplier) {
        //     const emails:string[] = []
        //     if (supplier.email) {
        //         emails.push(supplier.email)
        //     }
        //     if (supplier.documentsEmail) {
        //         emails.push(supplier.documentsEmail)
        //     }
        //     if (supplier.accountExecutiveEmail) {
        //         emails.push(supplier.accountExecutiveEmail)
        //     }
        //     if (supplier.supervisorEmail) {
        //         emails.push(supplier.supervisorEmail)
        //     }
        //     return emails
        // } 
        return emailsArray
      }, [customer],
    )
    
    React.useEffect(() => {
        if (selectedAction?.id === DOCUMENT_APPROVE_MANUALLY_ACTION_ID) {
            setTitle("Aprobar Manualmente")
            setMessage(`Estimado Cliente,

DHL Global Forwarding les comunica que su comprobante de retención No.  ${invoiceNumber ?? ""} se aceptará con fecha tardía de emisión por esta única ocasión,
            
En el caso de exceder los días de emisión establecidos por la ley nuestra plataforma de documentos electrónicos procederá a rechazarlos automáticamente y en consecuencia la gestión de cobro será al 100% de la factura.
                         
“REGLAMENTO DE COMPROBANTES DE VENTA, RETENCIÓN Y DOCUMENTOS COMPLEMENTARIOS
            
            Art. 8.- Obligación de emisión de comprobantes de venta y comprobantes de retención (…) Los agentes de retención en forma obligatoria emitirán el comprobante de retención en el momento que se realice el pago o se acredite en cuenta, lo que ocurra primero y estará disponible para la entrega al proveedor dentro de los cinco días hábiles siguientes al de presentación del comprobante de venta.”
                        
Agradezco su gestión, en caso de que existan dudas por favor comuníquese al siguiente correo electrónico: carlos.guilcapi@dhl.com
            
Agradecemos su gestión a esta solicitud.
                        
Saludos cordiales,
       
Corporate Tax Unit         
DHL Global Forwarding `)
            setSubject("Retención con error Aceptada")
        } else if (selectedAction?.id === DOCUMENT_REJECT_MANUALLY_ACTION_ID) {
            setTitle("Rechazar Manualmente")
            setMessage(`Estimado Cliente,
            
Con base en las resoluciones No. NAC-DGERCGC14-00787 y No. NAC-DGERCGC15-00000284, en donde se determinan los porcentajes de retención en la fuente de Impuesto a la Renta y del Impuesto al Valor Agregado IVA respectivamente, le comunicamos, en su calidad de agente de retención, la emisión de su comprobante de retención presenta errores en la aplicación de porcentajes; por lo tanto el No. De comprobante de retención No. ${invoiceNumber ?? ""} no se aplicará como abono a su estado de cuenta.

En caso de que existan dudas, agradeceremos se sirva comunicarse con el siguiente correo electrónico: carlos.guilcapi@dhl.com

Saludos cordiales.

Corporate Tax Unit
DHL Global Forwarding.
DP-DHL Group.`)
            setSubject("Rechazo Retención")    
        } else if (selectedAction?.id === DOCUMENT_MANAGE_ACTION_ID) {
            setTitle("Gestionar Nota de Crédito")
            setMessage("Por favor responder el correo a grace.rodriguez.r@dhl.com")
            setSubject(`Gestión nota de crédito ${invoiceNumber ?? ""}`)    
        } else if (selectedAction?.id === DOCUMENT_SEND_REIMBURSEMENT_ACTION_ID) {
            setTitle("Reembolso de Empleados")
            setMessage(reimbursementMessage)
            setSubject(`Reembolso de Empleados ${invoiceNumber ?? ""}`)
        }
    }, [selectedAction, invoiceNumber, reimbursementMessage])

    React.useEffect(() => {
        let data = `Hola,
            
Has generado una liquidación de reembolsos con el siguiente detalle:
            
DATE, LOCATIONS OR PLACES VISITED, DESCRIPTION, AMOUNT

`
        if (documentData !== undefined) {
            documentData.reimbursements?.forEach((reimbursement) => {
                let v1 = reimbursement.subtotalNoTaxesObject === undefined ? 0 : Number(reimbursement.subtotalNoTaxesObject)
                let v2 = reimbursement.subtotalRateZero === undefined ? 0 : Number(reimbursement.subtotalRateZero)
                let v3 = reimbursement.subtotalRateTwelve === undefined ? 0 : Number(reimbursement.subtotalRateTwelve)
                let v4 = reimbursement.iva === undefined ? 0 : Number(reimbursement.iva)
                let total = v1 + v2 + v3 + v4
                data += `${moment(reimbursement.emissionDate).format("YYYY-MM-DD")}, ${reimbursement.issuerName}, ${reimbursement.description}, ${total.toFixed(2)}
`
        })
            setReimbursementMessage(data)
        }
    }, [documentData])

    return (
        <Dialog open={open} onClose={onClose} maxWidth={"lg"}>
            <form onSubmit={handleSend}>
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent style={{ width: 800 }}>
                    <Autocomplete
                        freeSolo
                        multiple
                        options={getEmails()}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option}
                        fullWidth
                        value={emails}
                        openOnFocus
                        loading={supplier !== undefined ? supplierLoading : customerLoading}
                        loadingText={"cargando..."}
                        size="small"
                        ChipProps={{ size: "small" }}
                        onChange={(e, selectedValue, reason, detail) => {
                            if (reason === "create-option" && detail) {
                                if (isEmail(detail.option)) {
                                    setEmails(current => [...current, detail.option])
                                }
                                return
                            }
                            setEmails(selectedValue)
                        }}
                        renderOption={(option, { selected }) => {
                            return (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8, height: 50 }}
                                        checked={selected}
                                    />
                                    {option}
                                </React.Fragment>
                            )
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                                label={FIELDS.recipients.name}
                            />
                        )}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label={FIELDS.subject.name}
                        fullWidth
                        variant='outlined'
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        required
                        style={{ marginTop: 15, marginBottom: 10 }}
                    />
                    <TextField
                        margin="dense"
                        id="name"
                        label={FIELDS.message.name}
                        fullWidth
                        multiline
                        rows={10}
                        variant='outlined'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                        size='small'
                        disabled={dialogLoading}
                    >
                        {"Cerrar"}
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant='contained'
                        size='small'
                        disableElevation
                        disabled={dialogLoading}
                    >
                        {"Enviar"}
                    </Button>
                </DialogActions>
            </form>
            <FixedLoadingIndicator loading={dialogLoading} />
        </Dialog>
    )
}

export default SendMailDialog
