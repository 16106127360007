import React from 'react'
import { Button, CircularProgress, Dialog, DialogContent, Icon, IconButton, Menu, MenuItem, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField } from '@material-ui/core'
import { ConfirmDialog, UserForm, Search, FormDialog, ServerErrorHandler, LabelDisplayedRows, FixedLoadingIndicator } from 'components'
import { useInputValue, useTableModel } from 'hooks'
import { USER_PATH, NEW_PATH, AUTHENTICATION_PATH, USER_TABLE_COLUMNS, EDIT_PATH, deleteUserService, SMALL_ROWS_PER_PAGINATION_OPTIONS, ROOT_TITLE, USER_TITLE, changePasswordService, FIELDS } from 'lib'
import { useHistory, useLocation } from 'react-router'
import { IMessageConfig, IUser, UserStateFilter } from 'types'
import { ManagementContext } from 'context'
import { Alert } from '@material-ui/lab'
import { Helmet } from 'react-helmet'

const Users = () => {
    const { users, refreshUsers } = React.useContext(ManagementContext)
    const [filter, setFilter] = React.useState<UserStateFilter>("active")
    const [password, setPassword] = React.useState<string>("")
    const [openChangePassword, setOpenChangePassword] = React.useState(false)
    const { value: query, clearValue: handleClear, onChange: handleChange } = useInputValue()
    const { createSortHandler, handleChangePage, handleChangeRowsPerPage, order, orderBy, page, rows, rowsPerPage } = useTableModel()
    const location = useLocation()
    const history = useHistory()
    const [selected, setSelected] = React.useState<any | undefined>(undefined)
    const [actionsAnchorEl, setActionsAnchorEl] = React.useState<null | HTMLElement>(null)
    const [deleteId, setDeleteId] = React.useState<number>(-1)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<boolean>(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const getModel = React.useCallback((): any[] => {
        const src = filter === "all" ? [...users] : [...users].filter(o => o.active === (filter === "active") ? true : false)
        if (Boolean(query)) {
            return src.filter((s: any) => {
                return s.name.toUpperCase().includes(query.toUpperCase()) || s.email.toUpperCase().includes(query.toUpperCase())
                    || s.identification.toUpperCase().includes(query.toUpperCase())
            })
        }
        return src
    }, [users, query, filter])
    const handleEdit = () => {
        setActionsAnchorEl(null)
        history.push({
            state: {
                edit: true,
                values: selected
            },
            pathname: `${AUTHENTICATION_PATH}${USER_PATH}${EDIT_PATH}`
        })
    }
    const handleConfirmDelete = (id: number) => {
        setActionsAnchorEl(null)
        setDeleteId(id)
    }
    const handleDelete = async () => {
        try {
            setLoading(true)
            await deleteUserService(deleteId)
            setMessageConfig({ open: true, message: "Usuario eliminado!", severity: "success" })
            await refreshUsers()
            setLoading(false)
            return true
        } catch (error) {
            setMessageConfig({ open: true, message: "No se pudo eliminar el Usuario...!", severity: "error" })
            setLoading(false)
            return false
        }
    }
    const handleSubmitPassword = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            setLoading(true)
            if (selected?.userId) {
                await changePasswordService(selected?.userId, { ...selected, password })
                setMessageConfig({ open: true, message: "Contraseña actualizada", severity: "success" })
                setOpenChangePassword(false)
                setSelected(undefined)
                setPassword("")
                setActionsAnchorEl(null)
            }
            setLoading(false)
        } catch (error: any) {
            setLoading(false)
            setMessageConfig({ open: true, message: error.message, severity: "error" })
        }
    }
    React.useEffect(() => {
        const initPage = async () => {
            try {
                setLoading(true)
                await refreshUsers()
                setLoading(false)
            } catch (error) {
                setLoading(false)
                setError(true)
            }
        }
        initPage()
    }, [refreshUsers])
    return (
        <Paper className="flex flex-col h-full overflow-hidden p-4 pb-0">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${USER_TITLE}`}</title>
            </Helmet>
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <Search
                        onChange={handleChange}
                        query={query}
                        onClear={handleClear}
                        placeholer="Buscar por nombre,email o identificación"
                    />
                    <TextField
                        size="small"
                        variant="outlined"
                        style={{ width: 140, marginLeft: 15 }}
                        classes={{ root: "filter-text-field" }}
                        select
                        label="Estado"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value as UserStateFilter)}
                    >
                        <MenuItem dense value={"active"}>{"Activos"}</MenuItem>
                        <MenuItem dense value={"inactive"}>{"Inactivos"}</MenuItem>
                        <MenuItem dense value={"all"}>{"Todos"}</MenuItem>
                    </TextField>
                </div>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => history.push(`${AUTHENTICATION_PATH}${USER_PATH}${NEW_PATH}`)}
                >
                    <Icon fontSize="small" style={{ marginRight: 5 }}>add</Icon>
                    {"Nuevo"}
                </Button>
                <FormDialog
                    open={Boolean(location.pathname === `${AUTHENTICATION_PATH}${USER_PATH}${NEW_PATH}` || location.pathname === `${AUTHENTICATION_PATH}${USER_PATH}${EDIT_PATH}`)}
                    onClose={() => history.push(`${AUTHENTICATION_PATH}${USER_PATH}`)}
                >
                    <UserForm
                        onSuccess={() => { setMessageConfig({ open: true, message: Boolean(selected) ? "Usuario actualizado!" : "Usuario registrado!", severity: "success" }); refreshUsers(); if (Boolean(selected)) { setSelected(undefined) } }}
                        onError={() => setMessageConfig({ open: true, message: "No se pude registrar el Usuario", severity: "error" })}
                        onClose={() => history.push(`${AUTHENTICATION_PATH}${USER_PATH}`)}
                    />
                </FormDialog>
            </div>
            <div className="flex h-full flex-col mt-4 overflow-hidden">
                <div className="flex flex-grow overflow-auto">
                    <TableContainer>
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    {USER_TABLE_COLUMNS.map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            align={'left'}
                                            padding={"none"}
                                            sortDirection={orderBy === headCell.id ? order : false}
                                            style={{ maxWidth: 160, fontSize: '0.85em' }}
                                        >
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={createSortHandler(headCell.id as any)}
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <span className="hidden">
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                    <TableCell
                                        padding={"default"}
                                    />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rows(getModel()) as IUser[]).map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.userId}
                                        >
                                            <TableCell component="th" scope="row" padding="none">{row.name}</TableCell>
                                            <TableCell padding="none" align="left">{row.email}</TableCell>
                                            <TableCell padding="none" align="left">{row.identification}</TableCell>
                                            <TableCell padding="none" align="left">{new Date(row.validUntil).toLocaleDateString()}</TableCell>
                                            <TableCell padding="none" align="left"><span className={row.active ? "active-state-label" : "inactive-state-label"}>{row.active ? "Activo" : "Inactivo"}</span></TableCell>
                                            <TableCell padding="none" align="left">
                                                <div className="flex items-center">
                                                    <span className={row.canApprove ? "info-state-label" : "disabled-state-label"}>{"Aprobar"}</span>
                                                    <span className={`${row.canReject ? "info-state-label" : "disabled-state-label"} ml-3`}>{"Rechazar"}</span>
                                                </div>
                                            </TableCell>                                            
                                            <TableCell align="left">
                                                <IconButton onClick={(e) => { setActionsAnchorEl(e.currentTarget); setSelected(row) }} size="small">
                                                    <Icon fontSize="small">more_vert</Icon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                                <Menu
                                    anchorEl={actionsAnchorEl}
                                    open={Boolean(actionsAnchorEl)}
                                    onClose={() => setActionsAnchorEl(null)}
                                >
                                    <MenuItem onClick={handleEdit} dense>{"Editar"}</MenuItem>
                                    <MenuItem onClick={() => handleConfirmDelete(selected?.userId ?? -1)} dense>{"Eliminar"}</MenuItem>
                                    <MenuItem onClick={() => setOpenChangePassword(true)} dense>{"Cambiar contraseña"}</MenuItem>
                                </Menu>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div>
                    <TablePagination
                        rowsPerPageOptions={SMALL_ROWS_PER_PAGINATION_OPTIONS}
                        component="div"
                        count={getModel().length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Usuarios por página"
                        labelDisplayedRows={LabelDisplayedRows}
                    />
                </div>
            </div>
            <ConfirmDialog
                onCancel={() => setDeleteId(-1)}
                onConfirm={handleDelete}
                open={Boolean(deleteId !== -1)}
                title={"¿Estás seguro que deseas eliminar?"}
                loading={loading}
            />
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <FixedLoadingIndicator loading={loading && Boolean(deleteId === -1)} />
            <ServerErrorHandler
                error={error}
                onSuccess={() => setError(false)}
                tryAgain={refreshUsers}
            />
            <Dialog
                open={selected && openChangePassword}
                onClose={() => { setOpenChangePassword(false); setSelected(undefined); setPassword(""); setActionsAnchorEl(null) }}
            >
                <DialogContent>
                    <form onSubmit={handleSubmitPassword} className="w-full h-full flex items-center justify-center">
                        <div className="p-4">
                            <TextField
                                autoFocus
                                size="small"
                                label={FIELDS.newPassword.name}
                                variant="outlined"
                                fullWidth
                                required
                                value={password}
                                style={{ width: 350 }}
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                                helperText="No puedes ingresar ninguna contraseña antigua"
                            />
                            <div className="flex items-center justify-end mt-6">
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    disableElevation
                                    disabled={loading || !Boolean(password)}
                                    type="submit"
                                >
                                    {loading ? <CircularProgress style={{ color: "white" }} size={16} className="my-1" thickness={10} /> : "Cambiar"}
                                </Button>
                            </div>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </Paper>
    )
}


export default Users