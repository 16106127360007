import { useStorage } from "hooks"
import {
  CREDENTIALS_ERROR,
  ERROR_PATH,
  FORBIDDEN_PATH,
  getUserApprovalsByUserService,
  getUserInfoByUserIdService,
  isForbidden,
  isUnauthenticatedPath,
  LOCAL_STORAGE_ACCOUNT_KEY,
  loginService,
  NO_MODULES_ASIGNED_ERROR,
  ROOT_PATH,
} from "lib"
import React from "react"
import { useHistory, useLocation } from "react-router"
import { ILoginResponse, IModule, IUser } from "types"
import { IUserApproval } from "types/userApproval"

export interface IAuth {
  user: IUser | undefined
  login: (credentials: any) => Promise<IUser | undefined>
  logout: () => void
  searchingUser: boolean
  navigation: IModule[]
  selectedNav: IModule | undefined
  setSelectedNav: React.Dispatch<IModule | undefined>
  userApproval: IUserApproval | undefined
}

interface IAuthError {
  authError: boolean
}

const useAuth = (): IAuth & IAuthError => {
  const [user, setUser] = React.useState<IUser | undefined>(undefined)
  const [navigation, setNavigation] = React.useState<IModule[]>([])
  const [selectedNav, setSelectedNav] = React.useState<IModule | undefined>(
    undefined
  )
  const [searchingUser, setSearchingUser] = React.useState<boolean>(true)
  const [searchingPath, setSearchingPath] = React.useState<boolean>(false)
  const [userApproval, setUserApproval] = React.useState<
    IUserApproval | undefined
  >()
  const [authError, setAuthError] = React.useState(false)
  const { saveItem, deleteItem, getItem } = useStorage()
  const history = useHistory()
  const location = useLocation()
  const login = async (credentials: any): Promise<IUser | undefined> => {
    try {
      setAuthError(false)
      const loginResult: ILoginResponse = await loginService(credentials)
      if (loginResult.modules.length > 0) {
        saveItem(LOCAL_STORAGE_ACCOUNT_KEY, loginResult)
        setUser(loginResult.user)
        setNavigation(loginResult.modules)
        setSelectedNav(loginResult.modules[0])
        history.push(loginResult.modules[0].relativePath)
      } else {
        throw new Error(NO_MODULES_ASIGNED_ERROR)
      }
      const userApprovalResponse = await getUserApprovalsByUserService(
        loginResult.user.userId
      )
      setUserApproval(userApprovalResponse)
      return loginResult.user
    } catch (error: any) {
      console.log(error)
      throw new Error(
        error.message === NO_MODULES_ASIGNED_ERROR
          ? NO_MODULES_ASIGNED_ERROR
          : CREDENTIALS_ERROR
      )
    }
  }
  const logout = React.useCallback(() => {
    deleteItem(LOCAL_STORAGE_ACCOUNT_KEY)
    setUser(undefined)
    setNavigation([])
    history.replace(ROOT_PATH)
  }, [history, deleteItem])

  const searchStoredUser = React.useCallback(async () => {
    try {
      if (Boolean(user)) {
        return
      }
      setSearchingUser(true)
      const requestedPath = location.pathname
      const storedAccount: ILoginResponse = getItem(LOCAL_STORAGE_ACCOUNT_KEY)
      if (storedAccount) {
        try {
          setSearchingPath(true)
          if (requestedPath !== ROOT_PATH) {
            history.push(ROOT_PATH)
          }
          const result: ILoginResponse = await getUserInfoByUserIdService(
            storedAccount.user.userId
          )
          setUser(result.user)
          setNavigation(result.modules)
          saveItem(LOCAL_STORAGE_ACCOUNT_KEY, result)
          const userApprovalResponse = await getUserApprovalsByUserService(
            storedAccount.user.userId
          )
          setUserApproval(userApprovalResponse)
          setTimeout(() => {
            if (!isForbidden(result.modules, requestedPath)) {
              const module = result.modules.find((m) =>
                requestedPath.includes(m.relativePath)
              )
              if (module) {
                setSelectedNav(module)
                history.replace(requestedPath)
                setSearchingPath(false)
              } else if (requestedPath === ROOT_PATH && !searchingPath) {
                setSelectedNav(result.modules[0])
                history.push(result.modules[0].relativePath)
              }
            } else {
              if (
                isUnauthenticatedPath(requestedPath) &&
                storedAccount.modules.length > 0
              ) {
                history.push(storedAccount.modules[0].relativePath)
              } else {
                history.push({
                  pathname: ERROR_PATH + FORBIDDEN_PATH,
                  state: { requestedPath },
                })
              }
            }
            setTimeout(() => {
              setSearchingUser(false)
            }, 500)
          }, 600)
        } catch (error) {
          setAuthError(true)
          setSearchingUser(false)
        }
      } else {
        setTimeout(() => {
          setSearchingUser(false)
        }, 1200)
      }
    } catch (error) {}
  }, [getItem, location.pathname, history, user, saveItem, searchingPath])

  React.useEffect(() => {
    if (location.pathname !== "/upload/xmls") {
      searchStoredUser()
    }
  }, [searchStoredUser])

  return {
    user,
    login,
    logout,
    navigation,
    searchingUser,
    selectedNav,
    setSelectedNav,
    userApproval,
    authError,
  }
}

export default useAuth
