import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { DocumentDetail, DocumentHeader, DocumentActions, ServerErrorHandler, DocumentList, DocumentReimbursement } from 'components'
import { AuthContext, FileContext, ManagementContext } from 'context'
import { ERROR_PATH, FORBIDDEN_PATH, createDocumentService, validateDocumentDates, FIELDS, NEW_DOCUMENT_TITLE, ROOT_TITLE, assignPurchaseOrderService, calculateReimbusementsSummary } from 'lib'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useLocation } from 'react-router-dom'
import { IDocumentAdditionalInfo, IDocumentTypeInputs, IMessageConfig, IHeaderInputs, IAccountInfo, IDocument } from 'types'

const NewDocument = () => {
    const { user } = React.useContext(AuthContext)
    const { refreshSuppliers, refreshTaxes, refreshTaxTypes, refreshIdentificationTypes, suppliers, taxes, taxTypes, identificationTypes } = React.useContext(ManagementContext)
    const { uploadDocumentFiles } = React.useContext(FileContext)
    const location = useLocation()
    const history = useHistory()
    const [error, setError] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [type, setType] = React.useState<IDocumentTypeInputs | undefined>(undefined)
    const [document, setDocument] = React.useState<IDocumentAdditionalInfo>({ header: {}, detail: [] })
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const handleSubmit = async () => {
        try {
            if (document.header?.supplier?.requirePo && !Boolean(document.accountInfo?.purchaseOrder) && type?.type === "printed-invoice") {
                setMessageConfig({ open: true, message: "El proveedor seleccionado requiere orden de compra", severity: "warning" })
                return
            }
            if (document.header?.authorizationNumber?.length !== 10 && document.header?.authorizationNumber?.length !== 49 && type?.type === "printed-invoice") {
                setMessageConfig({ open: true, message: "El número de autorización debe tener 10 o 49 dígitos.", severity: "warning" })
                return
            }
            if (type?.withDetail && document.detail.length === 0) {
                setMessageConfig({ open: true, message: "Se debe agregar un detalle como mínimo", severity: "warning" })
                return
            }
            if ((type?.type === "cash-reimbursement" || type?.type === "employee-reimbursement") && (!document.header.supplier || !document.header.emissionDate || !document.reimbursements)) {
                setMessageConfig({ open: true, message: "Debe seleccionar un empleado, e ingresar al menos un reembolso.", severity: "warning" })
                return
            }
            setLoading(true)
            const createdDocument: IDocument = await createDocumentService(validateDocumentDates({ ...document, header: { ...document.header, type: type?.type } }, type?.inputs ?? {} as IHeaderInputs), user?.userId)
            if (document.accountInfo?.purchaseOrder?.documentId) {
                await assignPurchaseOrderService(document.accountInfo?.purchaseOrder?.documentId, user?.userId, createdDocument.documentId)
            }
            if (document.header.file) {
                await uploadDocumentFiles([{ description: "Archivo de respaldo", file: document.header.file.file }], createdDocument.documentId)
            }
            setLoading(false)
            setMessageConfig({ open: true, message: "Documento guardado exitosamente!", severity: "success" })
            setDocument({ header: {}, detail: [], accountInfo: {} as IAccountInfo })
        } catch (error: any) {
            setLoading(false)
            setMessageConfig({ open: true, message: error.message, severity: "error" })
        }
    }
    const handleChange = React.useCallback((key, value) => {
        setDocument(current => ({ ...current, header: { ...current.header, [key]: value } }))
    }, [])
    React.useEffect(() => {
        if (document.reimbursements) {
            const reimbursementSummary = calculateReimbusementsSummary(document.reimbursements)
            setDocument(current => ({ ...current, header: { ...current.header, reimbursementSummary } }))
        }
    }, [document.reimbursements])
    React.useEffect(() => {
        if ((location.state as any)?.type) {
            setType((location.state as any)?.type)
            setDocument({ header: {}, detail: [] })
        } else {
            history.push({ pathname: ERROR_PATH + FORBIDDEN_PATH, state: { requestedPath: location.pathname } })
        }
    }, [location, history])

    React.useEffect(() => {
        const init = async () => {
            try {
                setLoading(true)
                await refreshSuppliers()
                refreshTaxes()
                refreshTaxTypes()
                refreshIdentificationTypes()
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        init()
    }, [refreshTaxes, refreshSuppliers, refreshTaxTypes, refreshIdentificationTypes])

    return (
        <div className="flex flex-col h-full overflow-y-hidden">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${NEW_DOCUMENT_TITLE}`}</title>
            </Helmet>
            {
                type &&
                <React.Fragment>
                    <div className="p-1">
                        <DocumentHeader
                            inputs={type.inputs}
                            onChange={handleChange}
                            onChangeAccountInfo={(value) => setDocument(current => ({ ...current, accountInfo: value }))}
                            header={document.header}
                            requireAccountInfo={type.requireAccountInfo}
                            accountInfo={document.accountInfo}
                            loading={loading}
                            suppliers={suppliers}
                            identificationTypes={identificationTypes}
                            supplierCallback={type.supplierCallback}
                            requiredPo={Boolean(document?.header?.supplier?.requirePo)}
                            type={type.type}
                        />
                    </div>
                    {
                        (type.supplierCallback && document.header[FIELDS.supplier.key]) &&
                        <div className="p-1">
                            <DocumentList
                                taxTypes={taxTypes}
                                taxes={taxes}
                                onTaxSelected={(added) => setDocument(current => ({ ...current, detail: added }))}
                            />
                        </div>
                    }
                    <div className="p-1 flex-grow overflow-y-hidden">
                        {
                            type.withDetail &&
                            <DocumentDetail
                                detail={document.detail}
                                onAdd={(added) => setDocument(current => ({ ...current, detail: [...current.detail, added] }))}
                                onEdit={(edited) => setDocument(current => ({ ...current, detail: edited }))}
                                onDelete={(deleted) => setDocument(current => ({ ...current, detail: current.detail.filter(d => d !== deleted) }))}
                                descriptionOnly={type.descriptionOnly}
                                loading={loading}
                                taxes={taxes}
                                taxTypes={taxTypes}
                                purchaseOrderDescriptionOnly={type.purchaseOrderDescriptionOnly}
                                rate12TaxOnly={type.rate12TaxOnly}
                            />
                        }
                        {
                            type.withReimbursement &&
                            <DocumentReimbursement
                                withDetails={type.withReimbursementAndDetails}
                                onAdd={(added) => setDocument(current => ({ ...current, reimbursements: current.reimbursements ? [...current.reimbursements, added] : [added] }))}
                                onDelete={(deleted) => {
                                    setDocument(current => ({ ...current, reimbursements: current.reimbursements?.filter(d => d !== deleted) ?? [] }))
                                }}
                                onEdit={(edited) => setDocument(current => ({ ...current, reimbursements: edited }))}
                                reimbursements={document.reimbursements ?? []}
                                withExpenses
                                supplier={document.header.supplier}
                                withTaxSupport={type.type === "employee-reimbursement" || type.type === "cash-reimbursement"}
                                header={document.header}
                                type={type.type}
                            />
                        }
                    </div>
                    <DocumentActions
                        title={type.name}
                        onSubmit={handleSubmit}
                        loading={loading}
                    />
                    <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                        <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                            {messageConfig.message}
                        </Alert>
                    </Snackbar>
                    <ServerErrorHandler
                        error={error}
                        onSuccess={() => setError(false)}
                        tryAgain={async () => { try { await refreshSuppliers(); await refreshTaxes() } catch (e) { throw new Error("") } }}
                    />
                </React.Fragment>
            }
        </div>
    )
}

export default NewDocument
