export const DOCUMENT_PATH = "/document"
export const DOCUMENT_TYPE_PATH = "/document-type"
export const HISTORY_TYPE_PATH = "/history-type"
export const PURCHASE_ORDER_PATH = "/purchase-order"
export const SIMPLIFIED_PATH = "/simplified"
export const FILTER_PATH = "/filter"
export const NEW_DOCUMENT_PATH = "/new-document"
export const NEW_PURCHASE_ORDER_PATH = "/new-purchase-order"
export const EDIT_PURCHASE_ORDER_PATH = "/edit-purchase-order"
export const DOCUMENT_VIEW_PATH = "/document-view"
export const PURCHASE_ORDER_VIEW_PATH = "/purchase-order-view"
export const DASHBOARD_PATH = "/dashboard"
export const INVOICE_DASHBOARD_PATH = "/invoice-dashboard"
export const RETENTION_DASHBOARD_PATH = "/retention-dashboard"
export const ENTERED_PATH = "/entered"
export const PENDING_PATH = "/pending"
export const PENDING_INVOIVE_PATH = "/pending-invoice"
export const PENDING_RETENTION_PATH = "/pending-retention"
export const TO_APPROVE_PATH = "/to-approve"
export const APPROVED_PATH = "/approved"
export const REJECTED_PATH = "/rejected"
export const REJECTED_RETENTION_PATH = "/rejected-retention"
export const CANCELED_INVOICES_PATH = "/canceled-invoice"
export const RETENTION_PATH = "/retention"
export const REJECTED_HISTORY_PATH = "/rejected-history"
export const STATE_PATH = "/state"
export const COMPLETED_PATH = "/completed"
export const COMPLETED_INVOICE_PATH = "/completed-invoice"
export const COMPLETED_RETENTION_PATH = "/completed-retention"
export const ON_HOLD_INVOICES_PATH = "/on-hold-invoice"
export const ON_HOLD_RETENTION_PATH = "/on-hold-retention"
export const FINISHED_PATH = "/finished"
export const ASSIGNED_PATH = "/assigned"
export const ASSIGN_PATH = "/assign"
export const RESOURCES_PATH = "/resources"
export const RECEIVED_PATH = "/received"
export const AUTHENTICATION_PATH = "/authentication"
export const OFFICE_PATH = "/office"
export const MODULE_PATH = "/module"
export const INFO_PATH = "/info"
export const USER_PATH = "/user"
export const COST_CENTER_PATH = "/cost-center"
export const ACCOUNT_TYPE_PATH = "/account-type"
export const IDENTIFICATION_TYPE_PATH = "/identification-type"
export const TAX_PATH = "/tax"
export const MATRIX_PATH = "/matrix"
export const TAX_TYPE_PATH = "/tax-type"
export const TAX_PLAN_PATH = "/tax-plan"
export const TAX_PLAN_SUPPLIER_PATH = "/tax-plan-supplier"
export const SUPPLIER_PATH = "/supplier"
export const IDENTIFICATION_PATH = "/identification"
export const SUPPLIER_FILE_PATH = "/supplier-file"
export const REJECTION_CAUSE_PATH = "/rejection-cause"
export const ERROR_PATH = "/error"
export const FORBIDDEN_PATH = "/403"
export const ROOT_PATH = "/"
export const LIST_PATH = "/list"
export const NEW_PATH = "/new"
export const EDIT_PATH = "/edit"
export const LOGIN_PATH = "/login"
export const PASSWORD_PATH = "/password"
export const CHANGE_PASSWORD_PATH = "/change-password"
export const MODULE_VIEW_PATH = "/module-view"
export const USER_MODULE_PATH = "/user-module"
export const VIEW_PATH = "/view"
export const MASSIVE_PATH = "/massive"
export const ELECTRONIC_INVOICE_PATH = "/electronic-invoice"
export const FINISHED_ELECTRONIC_INVOICE_PATH = "/finished-electronic-invoice"
export const PENDING_ELECTRONIC_INVOICE_PATH = "/pending-electronic-invoice"
export const FINISHED_PRINTED_INVOICE_PATH = "/finished-printed-invoice"
export const PENDING_PRINTED_INVOICE_PATH = "/pending-printed-invoice"
export const FOREIGN_EXPENSES_LIQUIDATION_PATH = "/foreign-expenses-liquidation"
export const PENDING_FOREIGN_EXPENSES_LIQUIDATION_PATH = "/pending-foreign-expenses-liquidation"
export const FINISHED_FOREIGN_EXPENSES_LIQUIDATION_PATH = "/finished-foreign-expenses-liquidation"
export const BILL_OF_LADING_PATH = "/bill-of-lading"
export const PRINTED_INVOICE_PATH = "/printed-invoice"
export const PURCHASE_SETTLEMENT_PATH = "/purchase-settlement"
export const RETENTION_RECEIPT_PATH = "/retention-receipt"
export const CREDIT_NOTE_PATH = "/credit-note"
export const REIMBURSEMENT_PATH = "/reimbursement"
export const DOCUMENT_FILE_PATH = "/document-file"
export const DOWNLOAD_PATH = "/download"
export const PDF_PATH = "/pdf"
export const COMPANY_PATH = "/company"
export const CONDITION_PATH = "/condition"
export const CREATE_PATH = "/create"
export const APPROVE_PATH = "/approve"
export const REJECT_PATH = "/reject"
export const STATUS_PATH = "/status"
export const MANAGE_PATH = "/manage"
export const FLOW_PATH = "/flow"
export const CHANGE_PATH = "/change"
export const APPROVAL_LEVEL_PATH = "/approval-level"
export const USER_APPROVAL_PATH = "/user-approval"
export const REPORT_PATH = "/report"
export const SEND_BY_EMAIL_PATH = "/send-by-email"
export const SEND_REJECT_REPORT_EMAIL_PATH = "/send-reject-report"
export const REIMBURSEMENT_TAX_PLAN_PATH = "/reimbursement-tax-plan"
export const PENDING_REIMBURSEMENT_INVOICE_PATH = "/pending-reimbursement-invoice"
export const FINISHED_REIMBURSEMENT_INVOICE_PATH = "/finished-reimbursement-invoice"
export const SUPPORT_PATH = "/support"
export const SIMPLE_REPORT_PATH = "/simple-report"
export const SEND_PATH = "/send"
export const COUNT_PATH = "/count"
export const UPLOAD_PATH = "/upload"
export const MARK_PATH = "/mark"
export const ATS_PATH = "/ats"
export const COUPA_ERROR_PATH = "/coupa-error"
export const UNSEND_COUPA_PATH = "/unsend-coupa"
export const SEND_NOTIFICATION_EMAIL_PATH = "/send-notification-email"
export const HISTORY_PATH = "/history"
export const LAST_PATH = "/last"
export const ADDITIONAL_INFO_PATH = "/additional-info"
export const FILE_FROM_EMAIL_PATH = "/file-from-email"
export const CLOSING_PATH = "/closing"
export const CLOSING_DATE_PATH = "/closing-date"
export const CONFIGURATION_PATH = "/configuration"
export const EXCEPTION_PATH = "/exception"
export const REASON_PATH = "/reason"
export const CATEGORY_PATH = "/category"
export const CUSTOMER_PATH = "/customer"
export const ALL_PATH = "/all"
export const PAYMENTS_PATH = "/payment"
export const AUXILIAR_PATH = "/auxiliar"
export const ISSUED_PATH = "/issued"

export const DOCUMENT_TYPE_ROUTE_PARAM = ":document-type-id"

export const DOCUMENT_TYPES_PARAM = "documentTypes="
export const CONDITIONS_PARAM = "conditions="
export const HISTORY_TYPES_PARAM = "historyTypes="
export const RUC_OR_NAME_PARAM = "rucOrName="
export const RUC_OR_NAME_RECEPTOR_PARAM = "rucOrNameReceptor="
export const DOCUMENT_RANGE_END_PARAM = "documentRangeEnd="
export const DOCUMENT_RANGE_START_PARAM = "documentRangeStart="
export const RECEPTION_RANGE_END_PARAM = "receptionRangeEnd="
export const RECEPTION_RANGE_START_PARAM = "receptionRangeStart="
export const SERIAL_NUMBER_PARAM = "serialNumber="
export const PAGE_SIZE_PARAM = "pageSize="
export const RECEIVER_RUC_PARAM = "receiverRuc="
export const OFFSET_PARAM = "offset="
export const USER_ID_PARAM_END = "userId="
export const RETENTION_DOC_NUM_PARAM = "retentionDocumentNumber="
export const ASSOCIATED_DOC_NUM_PARAM = "associatedDocumentNumber="
export const PROCESS_STATUS_PARAM = "processStatus="
export const STATES_PARAM = "states="
export const TYPE_PARAM = "type="
export const FILTER_PARAM = "filter="
export const SEARCH_PARAM = "?"
export const AND_PARAM = "&"

export const USER_ID_PARAM = "?userId"

// EXPRESS
export const PATH_RETENTION = "/retention"
export const PATH_MASS = "/mass"
export const PATH_UPLOAD = "/upload"
export const PATH_XMLS = "/xmls"
