// import { IRejected } from "types"

const MESSAGE_TEXT = `Estimado Proveedor,

Su factura No XXXXXXXX ha sido rechazada por el siguiente motivo:

Xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -  Quien lo rechazo: Ricardo Capiello  (si no se ubica quién rechazó se elimina el campo en el comunicado)

 

Si tiene alguna duda por favor comuníquese con su contacto asignado.

En breve recibirá un correo solicitando la anulación del comprobante de retención aplicado a la factura en referencia, agradecemos de antemano su gestión.

 

Atentamente,


Cuentas por Pagar

DHL GLOBAL FORWARDING (ECUADOR) S. A.`

const REMINDER_TEXT = `** RECORDATORIO **

Estimado Proveedor,

Su factura No XXXXXXXX ha sido rechazada por el siguiente motivo:

Xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -  Quien lo rechazo: Ricardo Capiello

 

Agradecemos su gestión  anulación del comprobante de retención aplicado a la factura en referencia, agradecemos de antemano su gestión.

 

 

Atentamente,

 

Cuentas por Pagar

DHL GLOBAL FORWARDING (ECUADOR) S. A.`

export const RejectedDocumentListMock: any[] = [
    {
        documentId: 1,
        anuulled: false,
        associated: {
            documentId: 11,
            documentNumber: "00100200000002"
        },
        retention: {
            documentId: 12,
            documentNumber: "1772662"
        },
        //@ts-ignore
        receiver: {
            supplierId: 1,
            comercialName: "Proveedor 1",
            identification: "1726558378001",
        },
        requested: true,
        value: 8820,
        notification: {
            message: MESSAGE_TEXT,
            name: "Enviado",
            stateId: 1,
            receiver: "r1@gmail.com",
            sentDate: new Date()
        },
    },
    {
        documentId: 2,
        anuulled: false,
        associated: {
            documentId: 12,
            documentNumber: "00200200000002"
        },
        retention: {
            documentId: 13,
            documentNumber: "1272662"
        },
        //@ts-ignore
        receiver: {
            supplierId: 2,
            comercialName: "Proveedor 2",
            identification: "1726558375001",
        },
        requested: true,
        value: 84,
        notification: {
            message: MESSAGE_TEXT,
            name: "Enviado",
            stateId: 1,
            receiver: "r1@gmail.com",
            sentDate: new Date()
        },
        reminder: {
            message: REMINDER_TEXT,
            name: "Enviado",
            stateId: 1,
            receiver: "r1@gmail.com",
            sentDate: new Date()
        }
    },
    {
        documentId: 3,
        anuulled: false,
        associated: {
            documentId: 13,
            documentNumber: "00200200000003"
        },
        retention: {
            documentId: 14,
            documentNumber: "12726622"
        },
        //@ts-ignore
        receiver: {
            supplierId: 3,
            comercialName: "Proveedor 3",
            identification: "1726552375001",
        },
        requested: true,
        value: 782.8,
        notification: {
            message: MESSAGE_TEXT,
            name: "Enviado",
            stateId: 1,
            receiver: "r1@gmail.com",
            sentDate: new Date()
        }
    },
    {
        documentId: 4,
        anuulled: false,
        associated: {
            documentId: 14,
            documentNumber: "00200600000012"
        },
        retention: {
            documentId: 16,
            documentNumber: "12726672"
        },
        //@ts-ignore
        receiver: {
            supplierId: 4,
            comercialName: "Proveedor 4",
            identification: "1726552392001",
        },
        requested: true,
        value: 10.8,
        notification: {
            message: MESSAGE_TEXT,
            name: "Enviado",
            stateId: 1,
            receiver: "r1@gmail.com",
            sentDate: new Date()
        },
        reminder: {
            message: REMINDER_TEXT,
            name: "Enviado",
            stateId: 1,
            receiver: "r1@gmail.com",
            sentDate: new Date()
        }
    },
    {
        documentId: 5,
        anuulled: false,
        associated: {
            documentId: 15,
            documentNumber: "00600600000012"
        },
        retention: {
            documentId: 18,
            documentNumber: "1272667282"
        },
        //@ts-ignore
        receiver: {
            supplierId: 5,
            comercialName: "Proveedor 5",
            identification: "1726552392001",
        },
        requested: true,
        value: 10.8,
        notification: {
            message: MESSAGE_TEXT,
            name: "Enviado",
            stateId: 1,
            receiver: "r1@gmail.com",
            sentDate: new Date()
        },
        reminder: {
            message: REMINDER_TEXT,
            name: "Enviado",
            stateId: 1,
            receiver: "r1@gmail.com",
            sentDate: new Date()
        }
    },
]