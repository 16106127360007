import React from 'react'
import { IRetentionReportParams } from 'types'

interface Props {
}

interface Hook {
    params: IRetentionReportParams
    handleChangeParams: (key: string, value: any) => void
}

const useRetentionReportParams = (props?: Props): Hook => {
    const [params, setParams] = React.useState<IRetentionReportParams>({
        state: {
            conditions: [],
            documentEnd: new Date(),
            documentStart: new Date(),
        },
        retention: {
            documentEnd: new Date(),
            documentStart: new Date(),
        },
        accounting: {
            documentEnd: new Date(),
            documentStart: new Date(),
        },
        reclaim: {
            documentEnd: new Date(),
            documentStart: new Date(),
        }
    })
    const handleChangeParams = (key: string, value: any) => {
        setParams(current => ({
            ...current,
            [key]: value
        }))
    }
    return {
        params,
        handleChangeParams
    }
}

export default useRetentionReportParams
