import { Paper, Typography, Divider, Box } from '@material-ui/core'
import { ManagementContext } from 'context'
import React from 'react'
import { IDocumentHeader } from 'types'

interface Props {
    header: IDocumentHeader
}

const textStyle = { fontWeight: 600, fontSize: "0.8em" }

const EmissionData = (props: Props) => {
    const { header } = props
    const { identificationTypes, refreshIdentificationTypes } = React.useContext(ManagementContext)
    const getIdentificationInfo = React.useCallback(() => {
        const identificationType = identificationTypes.find(i => i.identificationTypeCode === header.supplier?.identificationType)
        return `${identificationType ? identificationType.name + ":" : ""} ${header.supplier ? header.supplier.identification : ""}`
    }, [identificationTypes, header])
    React.useEffect(() => {
        refreshIdentificationTypes()
    }, [refreshIdentificationTypes])
    return (
        <Paper variant="outlined" className="p-4 px-6" elevation={1}>
            <Typography style={{ fontWeight: 600, letterSpacing: 0.5, color: "#b1b1b1" }} variant="caption">
                {"DATOS DEL EMISOR"}
            </Typography>
            <div className="mt-2">
                <div className="my-2">
                    <Divider />
                </div>
                {
                    header.supplier &&
                    <React.Fragment>
                        <div className="flex mb-3 items-center">
                            <Typography style={textStyle}>
                                {getIdentificationInfo()}
                            </Typography>
                            <Typography style={textStyle} className="pl-5" variant="subtitle2">
                                {header.supplier.companyName ? `${header.supplier.companyName}` : ""}
                            </Typography>
                        </div>
                        <div className="flex">
                            <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                                {`Dirección:`}
                            </Typography>
                            <Typography style={textStyle} className="pl-2" variant="subtitle2">
                                {`${header.supplier.address}`}
                            </Typography>
                        </div>
                    </React.Fragment>
                }
                <div className="my-2"></div>
                {
                    header.supplier &&
                    <React.Fragment>
                        {
                            Boolean(header.supplier.specialTaxpayer) &&
                            <Typography color="textSecondary" style={textStyle}>
                                {`Contribuyente especial N° ${header.supplier.specialTaxpayer}`}
                            </Typography>
                        }
                        <Box color="info.light" className="flex items-center mt-3 rounded-full" style={{ width: "fit-content" }}>
                            <Typography variant="caption" style={{ fontWeight: 600, letterSpacing: 0.5, fontSize: "0.7em" }}>
                                {header.supplier.keepAccounting ? `CLIENTE OBLIGADO A LLEVAR CONTABILIDAD` : `CLIENTE NO OBLIGADO A LLEVAR CONTABILIDAD`}
                            </Typography>
                        </Box>
                    </React.Fragment>
                }
            </div>
        </Paper>
    )
}

export default EmissionData
