import React from 'react'
import { FormControlLabel, FormGroup, MenuItem, Switch, TextField, Typography } from '@material-ui/core'
import { COUNTRIES, FIELDS, INVOICE_PAYMENT_TYPES, PAYMENT_TERMS, PAYMENT_TYPES, REGIMEN_TYPES, SUJETO_RETENIDO_TYPES, SUSTENTO_CODES } from 'lib'
import { IAccountType, IIdentificationType, ISupplier } from 'types'
import { YES_NO_TYPES } from 'lib/constants/yesNoTypes'

interface Props {
    handleChange: (key: string, value: any) => void
    supplier: ISupplier
    accountTypes: IAccountType[]
    identificationTypes: IIdentificationType[]
    edit?: boolean
}

const inputStyle: React.CSSProperties = { width: "49%", marginBottom: 15 }

const SupplierGeneralForm = (props: Props) => {
    const { handleChange, supplier, identificationTypes, accountTypes } = props
    const [isExterior, setIsExterior] = React.useState<boolean>(false)
    const [isExteriorPayment, setIsExteriorPayment] = React.useState<boolean>(false)
    const [isDoubleTrib, setIsDoubleTrib] = React.useState<boolean>(false)
    const [initialCheck, setInitialCheck] = React.useState<boolean>(false)

    const checkPaymentType = (paymentType : string) => {
        if (paymentType === "01") {
            setIsExteriorPayment(false)
            handleChange(FIELDS.regimenType.key, undefined)
            handleChange(FIELDS.countryPayment.key, undefined)
            handleChange(FIELDS.doubleTribCon.key, undefined)
            handleChange(FIELDS.legalNormPayment.key, undefined)
            handleChange(FIELDS.fiscalRegimen.key, undefined)
        } else if (paymentType === "02") {
            setIsExteriorPayment(true)  
        } 
    }

    const checkIdentificationType = (identificationType : string) => {
        if (identificationType === "1") {
            handleChange(FIELDS.sujetoRetenidoType.key, undefined) 
            handleChange(FIELDS.paymentType.key, "01")
            setIsExterior(false)
        } else if (identificationType === "2") {
            handleChange(FIELDS.sujetoRetenidoType.key, undefined)
            handleChange(FIELDS.paymentType.key, "01")  
            setIsExterior(false) 
        } else if (identificationType === "3") {
            setIsExterior(true) 
        } else if (identificationType === "4") {
            setIsExterior(true) 
        } else {
            handleChange(FIELDS.sujetoRetenidoType.key, undefined) 
            handleChange(FIELDS.paymentType.key, "01")
            setIsExterior(false)
        }
    }

    const checkDoubleTribCon = (doubleTribCon : string) => {
        if (doubleTribCon === "NO") {
            setIsDoubleTrib(true)
        } else if (doubleTribCon === "SI") {
            setIsDoubleTrib(false)
            handleChange(FIELDS.legalNormPayment.key, undefined)
        } else {
            setIsDoubleTrib(false)
        }
    }

    const seleccionarChangeAction = (valor : boolean, tipo : string  )=> {
        if(valor === false){
            handleChange(tipo, valor)
        }else{
            if(tipo === 'especificText'){
                handleChange(tipo, valor) 
                handleChange('manualTaxes', !valor)
                handleChange('sriAutomatic',!valor)  
            }
            if(tipo === 'manualTaxes'){
                handleChange(tipo, valor)
                handleChange('especificText', !valor)
                handleChange('sriAutomatic',!valor) 
            }
            if(tipo === 'sriAutomatic'){
                handleChange(tipo, valor)
                handleChange('manualTaxes', !valor)
                handleChange('especificText',!valor) 
            }
        }
    }


    React.useEffect(() => {
        const init = () => {
            if (supplier && (supplier[FIELDS.paymentType.key] || supplier[FIELDS.identificationType.key] || supplier[FIELDS.doubleTribCon.key]) && !initialCheck) {
                supplier[FIELDS.identificationType.key] && checkIdentificationType(supplier[FIELDS.identificationType.key].toString())
                supplier[FIELDS.paymentType.key] && checkPaymentType(supplier[FIELDS.paymentType.key].toString())
                supplier[FIELDS.doubleTribCon.key] && checkDoubleTribCon(supplier[FIELDS.doubleTribCon.key].toString())
                setInitialCheck(true)
            }
        }

        init()
    }, [supplier, initialCheck])

    return (
        <div className="w-full h-auto flex flex-wrap justify-between items-start">
            <TextField
                autoFocus
                size="small"
                label={FIELDS.identification.name}
                variant="outlined"
                required
                style={inputStyle}
                value={supplier[FIELDS.identification.key]}
                onChange={(e) => handleChange(FIELDS.identification.key, e.target.value)}
            />
            <TextField
                value={supplier[FIELDS.identificationType.key]?.toString() || ''}
                label={FIELDS.identificationType.name}
                onChange={(e) => {
                        checkIdentificationType(e.target.value)
                        handleChange(FIELDS.identificationType.key, e.target.value)
                    }
                }
                required
                style={inputStyle}
                variant="outlined"
                size="small"
                select
            >
                {identificationTypes.map((identificationTypesT) => {
                    return <MenuItem key={identificationTypesT.identificationTypeId} value={identificationTypesT.identificationTypeId.toString()}>{identificationTypesT.description}</MenuItem>
                })}
            </TextField>
            <TextField
                size="small"
                label={FIELDS.companyName.name}
                style={inputStyle}
                variant="outlined"
                required
                value={supplier[FIELDS.companyName.key]}
                onChange={(e) => handleChange(FIELDS.companyName.key, e.target.value)}
            />
            {/*
            <Autocomplete
                options={City.getCitiesOfCountry("EC") ?? []}
                size="small"
                getOptionLabel={(option) => option.name}
                openOnFocus
                style={inputStyle}
                noOptionsText="No se encontraron ciudades registrados..."
                renderInput={
                    (params) =>
                        <TextField
                            {...params}
                            label={FIELDS.city.name}
                            variant="outlined"
                        />
                }
                value={supplier[FIELDS.city.key] ? (typeof supplier[FIELDS.city.key] === "string" ? City.getCitiesOfCountry("EC")?.find(c => c.name === supplier[FIELDS.city.key]) : supplier[FIELDS.city.key]) : null}
                onChange={(_, value) => handleChange(FIELDS.city.key, value)}
            />
            */}
            <TextField
                autoFocus
                size="small"
                label={FIELDS.email.name}
                variant="outlined"
                style={inputStyle}
                required
                value={supplier[FIELDS.email.key]}
                onChange={(e) => handleChange(FIELDS.email.key, e.target.value)}
            />
            <TextField
                size="small"
                label={FIELDS.address.name}
                variant="outlined"
                required
                style={inputStyle}
                value={supplier[FIELDS.address.key]}
                onChange={(e) => handleChange(FIELDS.address.key, e.target.value)}
            />
            {/*
            <TextField
                size="small"
                label={FIELDS.supplierModule.name}
                variant="outlined"
                style={inputStyle}
                value={supplier[FIELDS.supplierModule.key]?.toString() ?? ''}
                onChange={(e) => handleChange(FIELDS.supplierModule.key, e.target.value)}
                select
            >
                {SUPPLIER_TYPES.map((option) => {
                    return <MenuItem key={option.value} value={option.value}>
                        {option.value}
                    </MenuItem>
                })}
            </TextField>
            */}
            {/* <div className="flex items-center h-full my-2 w-full ml-2">
                <Typography color="textSecondary" variant="caption" style={{ letterSpacing: 0.5, fontWeight: 900 }}>
                    {"Los campos a continuación serán reemplazados por los campos de la pestaña CONTACTOS"}
                </Typography>
            </div>
            <TextField
                autoFocus
                size="small"
                label={FIELDS.email.name}
                variant="outlined"
                style={inputStyle}
                value={supplier[FIELDS.email.key]}
                onChange={(e) => handleChange(FIELDS.email.key, e.target.value)}
            />
            <TextField
                size="small"
                label={FIELDS.accountExecutiveEmail.name}
                variant="outlined"
                style={inputStyle}
                value={supplier[FIELDS.accountExecutiveEmail.key]}
                onChange={(e) => handleChange(FIELDS.accountExecutiveEmail.key, e.target.value)}
            />
            <TextField
                size="small"
                label={FIELDS.supervisorEmail.name}
                variant="outlined"
                style={inputStyle}
                value={supplier[FIELDS.supervisorEmail.key]}
                onChange={(e) => handleChange(FIELDS.supervisorEmail.key, e.target.value)}
            />
            <TextField
                size="small"
                label={FIELDS.documentsEmail.name}
                variant="outlined"
                style={inputStyle}
                value={supplier[FIELDS.documentsEmail.key]}
                onChange={(e) => handleChange(FIELDS.documentsEmail.key, e.target.value)}
            /> */}
            <div className="flex items-center h-full my-2 w-full ml-2">
                <Typography color="textSecondary" variant="caption" style={{ letterSpacing: 0.5, fontWeight: 900 }}>
                    {"Reglas de validación"}
                </Typography>
            </div>
            <FormGroup className={`p-2 w-full flex`} row>
                <FormControlLabel
                    control={<Switch color="primary" size="small" checked={Boolean(supplier[FIELDS.relatedPart.key])} onChange={(e) => handleChange(FIELDS.relatedPart.key, e.target.checked)} name={FIELDS.relatedPart.name} />}
                    label={FIELDS.relatedPart.name}
                />
            </FormGroup>
            <FormGroup className={`p-2 w-full flex`} row>
                <FormControlLabel
                    control={<Switch color="primary" size="small" checked={Boolean(supplier[FIELDS.physical.key])} onChange={(e) => handleChange(FIELDS.physical.key, e.target.checked)} name={FIELDS.physical.name} />}
                    label={FIELDS.physical.name}
                />
            </FormGroup>
            <div className="flex items-center h-full my-2 w-full ml-2">
                <Typography color="textSecondary" variant="caption" style={{ letterSpacing: 0.5, fontWeight: 900 }}>
                    {"Reglas de ingreso"}
                </Typography>
            </div>
            <FormGroup className={`p-2 w-full flex`} row>
                <FormControlLabel
                    control={<Switch color="primary" size="small" checked={Boolean(supplier[FIELDS.sriAutomatic.key])} onChange={(e) => seleccionarChangeAction(e.target.checked, FIELDS.sriAutomatic.key)} name={FIELDS.sriAutomatic.name} />}
                    label={FIELDS.sriAutomatic.name}
                />
            </FormGroup>
            <FormGroup className={`p-2 w-full flex`} row>
                <FormControlLabel
                    control={<Switch color="primary" size="small" checked={Boolean(supplier[FIELDS.manualTaxes.key])} onChange={(e) => seleccionarChangeAction( e.target.checked, FIELDS.manualTaxes.key)} name={FIELDS.manualTaxes.name} />}
                    label={FIELDS.manualTaxes.name}
                />
            </FormGroup>
            <FormGroup className={`p-2 w-full flex`} row>
                <FormControlLabel
                    control={<Switch color="primary" size="small" checked={Boolean(supplier[FIELDS.especificText.key])} onChange={(e) => seleccionarChangeAction(e.target.checked, FIELDS.especificText.key )} name={FIELDS.especificText.name} />}
                    label={FIELDS.especificText.name}
                />
            </FormGroup>
            <div className="flex items-center h-full mt-2 mb-6 w-full ml-2">
                <Typography color="textSecondary" variant="caption" style={{ letterSpacing: 0.5, fontWeight: 900 }}>
                    {"Información de Pagos al Exterior"}
                </Typography>
            </div>
            <div className="flex items-center h-full my-2 w-full ml-2" />
            <TextField
                value={supplier[FIELDS.sustentoCode.key]?.toString() ?? ''}
                label={FIELDS.sustentoCode.name}
                onChange={(e) =>
                    handleChange(FIELDS.sustentoCode.key, e.target.value)
                }
                required
                style={inputStyle}
                variant="outlined"
                size="small"
                select
            >
                 {SUSTENTO_CODES.map((option) => {
                    return <MenuItem key={option.value} value={option.value}>
                        {option.name}
                    </MenuItem>
                })}
            </TextField>
            <TextField
                value={supplier[FIELDS.invoicePaymentType.key]?.toString() ?? ''}
                label={FIELDS.invoicePaymentType.name}
                onChange={(e) =>
                    handleChange(FIELDS.invoicePaymentType.key, e.target.value)
                }
                required
                style={inputStyle}
                variant="outlined"
                size="small"
                select
            >
                 {INVOICE_PAYMENT_TYPES.map((option) => {
                    return <MenuItem key={option.value} value={option.value}>
                        {option.name}
                    </MenuItem>
                })}
            </TextField>
            <TextField
                value={supplier[FIELDS.paymentType.key]?.toString() ?? ''}
                label={FIELDS.paymentType.name}
                onChange={(e) =>{
                        checkPaymentType(e.target.value)
                        handleChange(FIELDS.paymentType.key, e.target.value)
                    }
                }
                required={isExterior}
                disabled={!isExterior}
                style={inputStyle}
                variant="outlined"
                size="small"
                select
            >
                 {PAYMENT_TYPES.map((option) => {
                    return <MenuItem key={option.value} value={option.value}>
                        {option.name}
                    </MenuItem>
                })}
            </TextField>
            <TextField
                value={supplier[FIELDS.sujetoRetenidoType.key]?.toString() ?? ''}
                label={FIELDS.sujetoRetenidoType.name}
                onChange={(e) =>
                    handleChange(FIELDS.sujetoRetenidoType.key, e.target.value)
                }
                required={isExterior}
                disabled={!isExterior}
                style={inputStyle}
                variant="outlined"
                size="small"
                select
            >
                 {SUJETO_RETENIDO_TYPES.map((option) => {
                    return <MenuItem key={option.value} value={option.value}>
                        {option.name}
                    </MenuItem>
                })}
            </TextField>
            <TextField
                value={supplier[FIELDS.regimenType.key]?.toString() ?? ''}
                label={FIELDS.regimenType.name}
                onChange={(e) =>
                    handleChange(FIELDS.regimenType.key, e.target.value)
                }
                required={isExteriorPayment}
                disabled={!isExteriorPayment}
                style={inputStyle}
                variant="outlined"
                size="small"
                select
            >
                 {REGIMEN_TYPES.map((option) => {
                    return <MenuItem key={option.value} value={option.value}>
                        {option.name}
                    </MenuItem>
                })}
            </TextField>
            <TextField
                value={supplier[FIELDS.countryPayment.key]?.toString() ?? ''}
                label={FIELDS.countryPayment.name}
                onChange={(e) =>
                    handleChange(FIELDS.countryPayment.key, e.target.value)
                }
                required={isExteriorPayment}
                disabled={!isExteriorPayment}
                style={inputStyle}
                variant="outlined"
                size="small"
                select
            >
                 {COUNTRIES.map((option) => {
                    return <MenuItem key={option.value} value={option.value}>
                        {option.value} - {option.name}
                    </MenuItem>
                })}
            </TextField>
            <TextField
                value={supplier[FIELDS.doubleTribCon.key]?.toString() ?? ''}
                label={FIELDS.doubleTribCon.name}
                onChange={(e) => {
                        checkDoubleTribCon(e.target.value)
                        handleChange(FIELDS.doubleTribCon.key, e.target.value)
                    }
                }
                required={isExteriorPayment}
                disabled={!isExteriorPayment}
                style={inputStyle}
                variant="outlined"
                size="small"
                select
            >
                 {YES_NO_TYPES.map((option) => {
                    return <MenuItem key={option.value} value={option.value}>
                        {option.value}
                    </MenuItem>
                })}
            </TextField>
            <TextField
                value={supplier[FIELDS.legalNormPayment.key]?.toString() ?? ''}
                label={FIELDS.legalNormPayment.name}
                onChange={(e) =>
                    handleChange(FIELDS.legalNormPayment.key, e.target.value)
                }
                required={isDoubleTrib}
                disabled={!isDoubleTrib}
                style={inputStyle}
                variant="outlined"
                size="small"
                select
            >
                 {YES_NO_TYPES.map((option) => {
                    return <MenuItem key={option.value} value={option.value}>
                        {option.value}
                    </MenuItem>
                })}
            </TextField>
            <TextField
                value={supplier[FIELDS.fiscalRegimen.key]?.toString() ?? ''}
                label={FIELDS.fiscalRegimen.name}
                onChange={(e) =>
                    handleChange(FIELDS.fiscalRegimen.key, e.target.value)
                }
                required={isExteriorPayment}
                disabled={!isExteriorPayment}
                style={inputStyle}
                variant="outlined"
                size="small"
                select
            >
                 {YES_NO_TYPES.map((option) => {
                    return <MenuItem key={option.value} value={option.value}>
                        {option.value}
                    </MenuItem>
                })}
            </TextField>
        </div >
    )
}

export default SupplierGeneralForm
