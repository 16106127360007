import {
  ADDITIONAL_CREDIT_NOTE_DOCUMENT_ACTIONS,
  ADDITIONAL_REIMBURSEMENT_DOCUMENT_ACTIONS,
  ADDITIONAL_RETENTION_DOCUMENT_ACTIONS,
  DOCUMENT_ACTIONS,
  DOCUMENT_TYPE_IDS,
} from "lib/constants/document";
import { FIELDS } from "lib/constants/fields";
import { DEFAULT_TAX, SRI_SUBTOTAL_BY_TAXES_GUIDE } from "lib/constants/tax";
import { TRANSFORM_FIELDS } from "lib/constants/transformFields";
import {
  DetalleComprobanteRetencion,
  DetalleComprobanteRetencionATS,
  DetalleImpuesto,
  Detalles,
  IDetail,
  IDetailsSummary,
  IDocumentAction,
  IDocumentAdditionalInfo,
  IDocumentAditional,
  IDocumentType,
  IDocumentView,
  IElectronicCreditOrder,
  IElectronicInvoice,
  IElectronicPurchaseSettlement,
  IElectronicRetentionVoucher,
  IHeaderInputs,
  InfoAdicional,
  IRefund,
  IReimbursement,
  ITax,
  ITaxType,
  Reembolsos,
} from "types";
import moment from "moment";
import "moment/locale/es";

moment.locale("es");

export const validateDocumentDates = (
  document: IDocumentAdditionalInfo,
  inputs: IHeaderInputs
): IDocumentAdditionalInfo => {
  if (
    !document.header[FIELDS.emissionDate.key] &&
    inputs[FIELDS.emissionDate.key]
  ) {
    document.header[FIELDS.emissionDate.key] = new Date();
  }
  return document;
};

export const getDocumentElectronicInfo = (
  document: IDocumentView,
  electronicalData: string,
  taxes: ITax[],
  taxTypes: ITaxType[],
  documentTypes: IDocumentType[]
): IDocumentView => {
  if (!Boolean(electronicalData)) {
    return {
      ...document,
      data: { header: { supplier: {} }, detail: [] },
      additionals: [],
    } as IDocumentView;
  }
  const data = JSON.parse(electronicalData);
  delete data.signature;
  if (data[TRANSFORM_FIELDS.infoFactura.key]) {
    const invoice: IElectronicInvoice = { ...data };
    const additionalInfo: IDocumentAdditionalInfo = {
      header: {
        supplier: {
          identification: invoice.infoTributaria.ruc,
          companyName: invoice.infoTributaria.razonSocial,
          address: invoice.infoTributaria.dirMatriz,
          identificationType: invoice.infoFactura.tipoIdentificacionComprador,
          keepAccounting:
            invoice.infoFactura.obligadoContabilidad ===
            TRANSFORM_FIELDS.keepAccountingConfirmation.name,
          specialTaxpayer: invoice.infoFactura.contribuyenteEspecial,
        },
        invoiceNumber: `${invoice.infoTributaria.estab} - ${invoice.infoTributaria.ptoEmi} - ${invoice.infoTributaria.secuencial}`,
        emissionDate: invoice.infoFactura.fechaEmision,
        authorizationNumber: document.document.documentAuthorization,
        authorizationDate: document.document.authorizationDate,
        environment:
          invoice.infoTributaria.ambiente === "1"
            ? "Certificación"
            : "Producción",
      },
      detail: transformElectronicInvoiceDetails(
        invoice.detalles,
        taxes,
        taxTypes
      ),
    };
    return {
      ...document,
      data: additionalInfo,
      additionals: transformElectronicInvoiceAditionals(invoice.infoAdicional),
      refunds: invoice.reembolsos
        ? transformRefunds(invoice.reembolsos)
        : undefined,
    } as IDocumentView;
  }
  if (data[TRANSFORM_FIELDS.infoNotaCredito.key]) {
    const creditOrder: IElectronicCreditOrder = { ...data };
    const additionalInfo: IDocumentAdditionalInfo = {
      header: {
        supplier: {
          identification: creditOrder.infoTributaria.ruc,
          companyName: creditOrder.infoTributaria.nombreComercial,
          address: creditOrder.infoTributaria.dirMatriz,
          identificationType:
            creditOrder.infoNotaCredito.tipoIdentificacionComprador,
          keepAccounting:
            creditOrder.infoNotaCredito.obligadoContabilidad ===
            TRANSFORM_FIELDS.keepAccountingConfirmation.name,
          specialTaxpayer: creditOrder.infoNotaCredito.contribuyenteEspecial,
        },
        invoiceNumber: `${creditOrder.infoTributaria.estab} - ${creditOrder.infoTributaria.ptoEmi} - ${creditOrder.infoTributaria.secuencial}`,
        emissionDate: creditOrder.infoNotaCredito.fechaEmision,
        authorizationNumber: document.document.documentAuthorization,
        authorizationDate: document.document.authorizationDate,
        environment:
          creditOrder.infoTributaria.ambiente === "1"
            ? "Certificación"
            : "Producción",
        associatedDocumentNumber: creditOrder.infoNotaCredito.numDocModificado,
        associatedDocumentDate:
          creditOrder.infoNotaCredito.fechaEmisionDocSustento,
      },
      detail: transformElectronicInvoiceDetails(
        creditOrder.detalles,
        taxes,
        taxTypes
      ),
    };
    return {
      ...document,
      data: additionalInfo,
      additionals: transformElectronicInvoiceAditionals(
        creditOrder.infoAdicional
      ),
    } as IDocumentView;
  }
  if (data[TRANSFORM_FIELDS.infoCompRetencion.key]) {
    const retentionVoucher: IElectronicRetentionVoucher = { ...data };
    const additionalInfo: IDocumentAdditionalInfo = {
      header: {
        supplier: {
          identification: retentionVoucher.infoTributaria.ruc,
          companyName: retentionVoucher.infoTributaria.nombreComercial,
          address: retentionVoucher.infoTributaria.dirMatriz,
          identificationType:
            retentionVoucher.infoCompRetencion.tipoIdentificacionSujetoRetenido,
          keepAccounting:
            retentionVoucher.infoCompRetencion.obligadoContabilidad ===
            TRANSFORM_FIELDS.keepAccountingConfirmation.name,
          specialTaxpayer:
            retentionVoucher.infoCompRetencion.contribuyenteEspecial,
        },
        invoiceNumber: `${retentionVoucher.infoTributaria.estab} - ${retentionVoucher.infoTributaria.ptoEmi} - ${retentionVoucher.infoTributaria.secuencial}`,
        emissionDate: retentionVoucher.infoCompRetencion.fechaEmision,
        authorizationNumber: document.document.documentAuthorization,
        authorizationDate: document.document.authorizationDate,
        environment:
          retentionVoucher.infoTributaria.ambiente === "1"
            ? "Certificación"
            : "Producción",
      },
      detail: transformElectronicRetentionVoucherDetails(
        retentionVoucher.impuestos?.impuesto,
        retentionVoucher.docsSustento?.docSustento,
        documentTypes,
        taxTypes
      ),
    };
    return {
      ...document,
      data: additionalInfo,
      additionals: transformElectronicInvoiceAditionals(
        retentionVoucher.infoAdicional
      ),
    } as IDocumentView;
  }
  if (data[TRANSFORM_FIELDS.infoLiquidacionCompra.key]) {
    const purchaseSettlement: IElectronicPurchaseSettlement = { ...data };
    const additionalInfo: IDocumentAdditionalInfo = {
      header: {
        supplier: {
          identification: purchaseSettlement.infoTributaria.ruc,
          companyName: purchaseSettlement.infoTributaria.nombreComercial,
          address: purchaseSettlement.infoTributaria.dirMatriz,
          identificationType:
            purchaseSettlement.infoLiquidacionCompra
              .tipoIdentificacionProveedor,
          keepAccounting:
            purchaseSettlement.infoLiquidacionCompra.obligadoContabilidad ===
            TRANSFORM_FIELDS.keepAccountingConfirmation.name,
          specialTaxpayer:
            purchaseSettlement.infoLiquidacionCompra.contribuyenteEspecial,
        },
        invoiceNumber: `${purchaseSettlement.infoTributaria.estab} - ${purchaseSettlement.infoTributaria.ptoEmi} - ${purchaseSettlement.infoTributaria.secuencial}`,
        emissionDate: purchaseSettlement.infoLiquidacionCompra.fechaEmision,
        authorizationNumber: document.document.documentAuthorization,
        authorizationDate: document.document.authorizationDate,
        environment:
          purchaseSettlement.infoTributaria.ambiente === "1"
            ? "Certificación"
            : "Producción",
      },
      detail: transformElectronicInvoiceDetails(
        purchaseSettlement.detalles,
        taxes,
        taxTypes
      ),
    };
    return {
      ...document,
      data: additionalInfo,
      additionals: [],
    } as IDocumentView;
  }
  return { ...document } as IDocumentView;
};

const transformElectronicInvoiceDetails = (
  detalles: Detalles,
  taxes: ITax[],
  taxTypes: ITaxType[]
): IDetail[] => {
  const details: IDetail[] = [];
  for (const detalle of detalles.detalle) {
    const taxType = taxTypes.find(
      (t) =>
        t.taxCode.toString() ===
          detalle.impuestos.impuesto[0]?.codigo.toString() && !t.forRetention
    );
    const tax = taxes.find(
      (t) =>
        t.taxTypeId === taxType?.taxId &&
        t.taxCode.toString() ===
          detalle.impuestos.impuesto[0]?.codigoPorcentaje.toString()
    );
    details.push({
      description: detalle.descripcion,
      discount: detalle.descuento,
      purchaseCode: detalle.codigoPrincipal,
      quantity: detalle.cantidad,
      unitPrice: detalle.precioUnitario,
      subtotalNoTaxes: detalle.precioTotalSinImpuesto,
      tax: tax ?? DEFAULT_TAX,
      total: detalle.precioUnitario * detalle.cantidad,
    });
  }
  return details;
};

const transformElectronicRetentionVoucherDetails = (
  detalles: DetalleComprobanteRetencion[] | undefined,
  detallesATS: DetalleComprobanteRetencionATS[] | undefined,
  documentTypes: IDocumentType[],
  taxTypes: ITaxType[]
): IDetail[] => {
  const details: IDetail[] = [];
  if (detalles) {
    for (const detalle of detalles) {
      const taxType = taxTypes.find(
        (t) => t.taxCode.toString() === detalle.codigo && t.forRetention
      );
      const documentType = documentTypes.find(
        (d) => d.documentCode === detalle.codDocSustento
      );
      const supportEmitionDate =
        new Date(detalle.fechaEmisionDocSustento).toString() !== "Invalid Date"
          ? new Date(detalle.fechaEmisionDocSustento)
          : moment(detalle.fechaEmisionDocSustento, [
              "DD-MM-YYYY",
              "MM-DD-YYYY",
            ]).toDate();
      details.push({
        description: "",
        taxBase: detalle.baseImponible,
        supportEmitionDate:
          supportEmitionDate.toString() !== "Invalid Date"
            ? supportEmitionDate
            : new Date(),
        supportDocumentNumber: detalle.numDocSustento,
        retainedPercentage: detalle.porcentajeRetener,
        retainedValue: detalle.valorRetenido,
        taxAndTaxType: `${detalle.codigoRetencion} - ${taxType?.name ?? ""}`,
        documentType: documentType?.documentName,
      });
    }
  }
  if (detallesATS) {
    for (const detalleATS of detallesATS) {
      for (const retencion of detalleATS.retenciones.retencion) {
        const taxType = taxTypes.find(
          (t) => t.taxCode.toString() === retencion.codigo && t.forRetention
        );
        const documentType = documentTypes.find(
          (d) => d.documentCode === detalleATS.codSustento
        );
        const supportEmitionDate =
          new Date(detalleATS.fechaEmisionDocSustento).toString() !==
          "Invalid Date"
            ? new Date(detalleATS.fechaEmisionDocSustento)
            : moment(detalleATS.fechaEmisionDocSustento, [
                "DD-MM-YYYY",
                "MM-DD-YYYY",
              ]).toDate();
        details.push({
          description: "",
          taxBase: retencion.baseImponible,
          supportEmitionDate:
            supportEmitionDate.toString() !== "Invalid Date"
              ? supportEmitionDate
              : new Date(),
          supportDocumentNumber: detalleATS.numDocSustento,
          retainedPercentage: retencion.porcentajeRetener,
          retainedValue: retencion.valorRetenido,
          taxAndTaxType: `${retencion.codigoRetencion} - ${
            taxType?.name ?? ""
          }`,
          documentType: documentType?.documentName,
        });
      }
    }
  }
  return details;
};

const transformElectronicInvoiceAditionals = (
  infoAditional: InfoAdicional
): IDocumentAditional[] => {
  if (!Boolean(infoAditional)) {
    return [];
  }
  const aditionals: IDocumentAditional[] = [];
  for (const adicional of infoAditional.campoAdicional) {
    aditionals.push({ key: adicional.nombre, value: adicional.value });
  }
  return aditionals;
};

export const getDocumentActions = (
  documentTypeId: number
): IDocumentAction[] => {
  const actions: IDocumentAction[] = [];
  // if (documentTypeId === DOCUMENT_TYPE_IDS.retentionReceipt || documentTypeId === DOCUMENT_TYPE_IDS.printedRetentionReceipt) {
  //     for (const key in ADDITIONAL_RETENTION_DOCUMENT_ACTIONS) {
  //         if (Object.prototype.hasOwnProperty.call(ADDITIONAL_RETENTION_DOCUMENT_ACTIONS, key)) {
  //             const documentAction = ADDITIONAL_RETENTION_DOCUMENT_ACTIONS[key]
  //             actions.push(documentAction)
  //         }
  //     }
  // } else {
  //     for (const key in DOCUMENT_ACTIONS) {
  //         if (Object.prototype.hasOwnProperty.call(DOCUMENT_ACTIONS, key)) {
  //             const documentAction = DOCUMENT_ACTIONS[key]
  //             actions.push(documentAction)
  //         }
  //     }
  //     if (documentTypeId === DOCUMENT_TYPE_IDS.creditNote || documentTypeId === DOCUMENT_TYPE_IDS.printedCreditNote) {
  //         for (const key in ADDITIONAL_CREDIT_NOTE_DOCUMENT_ACTIONS) {
  //             if (Object.prototype.hasOwnProperty.call(ADDITIONAL_CREDIT_NOTE_DOCUMENT_ACTIONS, key)) {
  //                 const documentAction = ADDITIONAL_CREDIT_NOTE_DOCUMENT_ACTIONS[key]
  //                 actions.push(documentAction)
  //             }
  //         }
  //     }
  //     if (documentTypeId === DOCUMENT_TYPE_IDS.employeeReimbursement) {
  //         for (const key in ADDITIONAL_REIMBURSEMENT_DOCUMENT_ACTIONS) {
  //             if (Object.prototype.hasOwnProperty.call(ADDITIONAL_REIMBURSEMENT_DOCUMENT_ACTIONS, key)) {
  //                 const documentAction = ADDITIONAL_REIMBURSEMENT_DOCUMENT_ACTIONS[key]
  //                 actions.push(documentAction)
  //             }
  //         }
  //     }
  // }
  for (const key in DOCUMENT_ACTIONS) {
    if (Object.prototype.hasOwnProperty.call(DOCUMENT_ACTIONS, key)) {
      const documentAction = DOCUMENT_ACTIONS[key];
      actions.push(documentAction);
    }
  }
  return actions;
};

export const calculateDetailSummary = (detail: IDetail[]): IDetailsSummary => {
  const summary: IDetailsSummary = {
    subtotal: 0,
    taxesValue: 0,
    total: 0,
    discount: 0,
  };
  for (const d of detail) {
    const subtotal = (d.quantity ?? 1) * (d.unitPrice ?? 0);
    summary.subtotal = summary.subtotal + subtotal;
    const taxesValue =
      (((d.unitPrice ?? 0) * (d.quantity ?? 1) - (d.discount ?? 0)) *
        (d.tax as ITax)?.percentage) /
      100;
    summary.taxesValue = summary.taxesValue + taxesValue;
    const discount = d.discount ?? 0;
    summary.discount =
      parseFloat(summary.discount.toString()) + parseFloat(discount.toString());
  }
  summary.total = summary.subtotal + summary.taxesValue - summary.discount;
  return summary;
};

const transformRefunds = (data: Reembolsos): IRefund[] => {
  const refunds: IRefund[] = [];
  for (const r of data.reembolsoDetalle) {
    let taxDetail: DetalleImpuesto = {} as DetalleImpuesto;
    for (const d of r.detalleImpuestos.detalleImpuesto) {
      if (d.codigo === "2") {
        taxDetail = d;
        break;
      }
    }
    refunds.push({
      code: r.codDocReembolso,
      emitionDate: r.fechaEmisionDocReembolso,
      authorizationDoc: r.numeroautorizacionDocReemb,
      identification: r.identificacionProveedorReembolso,
      sequential: `${r.estabDocReembolso} - ${r.ptoEmiDocReembolso} - ${r.secuencialDocReembolso}`,
      rate: taxDetail.tarifa ?? "",
      taxSupport: taxDetail.baseImponibleReembolso ?? 0,
      taxRefund: taxDetail.impuestoReembolso ?? 0,
    });
  }
  return refunds;
};

export const getReimbursementInfoByElectronicInvoice = (
  document: IDocumentView,
  electronicalData: string
): IReimbursement => {
  if (!Boolean(electronicalData)) {
    return {} as IReimbursement;
  }
  const data = JSON.parse(electronicalData);
  delete data.signature;
  if (data[TRANSFORM_FIELDS.infoFactura.key]) {
    const invoice: IElectronicInvoice = { ...data };
    const subtotalRateTwelve =
      invoice.infoFactura.totalConImpuestos.totalImpuesto.find(
        (t) =>
          t.codigo === SRI_SUBTOTAL_BY_TAXES_GUIDE.iva12.code &&
          t.codigoPorcentaje ===
            SRI_SUBTOTAL_BY_TAXES_GUIDE.iva12.percentageCode
      )?.baseImponible ?? 0;
      const subtotalRateQuince = invoice.infoFactura.totalConImpuestos.totalImpuesto.find(
        t => t.codigo === SRI_SUBTOTAL_BY_TAXES_GUIDE.iva15.code && t.codigoPorcentaje === SRI_SUBTOTAL_BY_TAXES_GUIDE.iva15.percentageCode
    )?.baseImponible ?? 0
    const subtotalRateZero =
      invoice.infoFactura.totalConImpuestos.totalImpuesto.find(
        (t) =>
          t.codigo === SRI_SUBTOTAL_BY_TAXES_GUIDE.iva0.code &&
          t.codigoPorcentaje === SRI_SUBTOTAL_BY_TAXES_GUIDE.iva0.percentageCode
      )?.baseImponible ?? 0;
    const subtotalNoTaxesObjectWithoutTips =
      invoice.infoFactura.totalConImpuestos.totalImpuesto.find(
        (t) =>
          t.codigo === SRI_SUBTOTAL_BY_TAXES_GUIDE.noIvaObject.code &&
          t.codigoPorcentaje ===
            SRI_SUBTOTAL_BY_TAXES_GUIDE.noIvaObject.percentageCode
      )?.baseImponible ?? 0;
    const iva =
      invoice.infoFactura.totalConImpuestos.totalImpuesto.find(
        (t) =>
          t.codigo === SRI_SUBTOTAL_BY_TAXES_GUIDE.iva12.code &&
          t.codigoPorcentaje ===
            SRI_SUBTOTAL_BY_TAXES_GUIDE.iva12.percentageCode
      )?.valor ?? 0;
      const iva15 = invoice.infoFactura.totalConImpuestos.totalImpuesto.find(
        t => t.codigo === SRI_SUBTOTAL_BY_TAXES_GUIDE.iva15.code && t.codigoPorcentaje === SRI_SUBTOTAL_BY_TAXES_GUIDE.iva15.percentageCode
    )?.valor ?? 0
    const subtotalNoTaxesObject = invoice.infoFactura.propina
      ? subtotalNoTaxesObjectWithoutTips + invoice.infoFactura.propina
      : subtotalNoTaxesObjectWithoutTips;
    return {
      authorizationNumber: document.document.documentAuthorization,
      documentNumber: document.document.serialNumber,
      emissionDate: moment(invoice.infoFactura.fechaEmision, [
        "DD-MM-YYYY",
        "MM-DD-YYYY",
      ]).toDate(),
      issuerIdentification: invoice.infoTributaria.ruc,
      issuerName: invoice.infoTributaria.razonSocial,
      subtotalRateTwelve,
      subtotalRateZero,
      subtotalNoTaxesObject,
      iva15,
      subtotalRateQuince,
      iva,
      receiverIdentification: invoice.infoFactura.identificacionComprador,
      receiverName: invoice.infoFactura.razonSocialComprador,
      documentSupport:"01",
    };
  }
  return {} as IReimbursement;
};
