import React from 'react'
import Lottie from 'react-lottie'
import mailSend from 'assets/animations/mail-send.json'
import { Icon, IconButton, Snackbar, Typography } from '@material-ui/core'
import { EmailList } from 'components'
import { useRetentionReportParams } from 'hooks'
import { ROOT_TITLE, SEND_REPORT_TITLE, sendRetentionReportByEmailService, transformRetentionReportParams } from 'lib'
import { Helmet } from 'react-helmet'
import { Alert } from '@material-ui/lab'
import { AuthContext } from 'context'
import RetentionReportTypePicker from 'components/RetentionReportTypePicker/RetentionReportTypePicker'

const SendReports = () => {
    const [selected, setSelected] = React.useState<number[]>([])
    const { handleChangeParams, params } = useRetentionReportParams()
    const { user } = React.useContext(AuthContext)
    const [open, setOpen] = React.useState(false)
    const toggleSelect = (typeId: number) => {
        setSelected(current => {
            return current.includes(typeId) ? current.filter(c => c !== typeId) : [...current, typeId]
        })
    }
    const onSubmit = async (emails: string[]) => {
         try {
             setSelected([])
             setOpen(true)
             await sendRetentionReportByEmailService(transformRetentionReportParams(user?.name ?? "", selected, emails, params.state, params.retention, params.accounting, params.reclaim))
         } catch (error) {
             console.log(error)
         }
        //console.log("1")
        //console.log(selected)
        //setSelected([])
        //console.log("2")
        //console.log(selected)
    }
    return (
        <div className="w-full flex flex-col h-full overflow-hidden">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${SEND_REPORT_TITLE}`}</title>
            </Helmet>
            <div className="flex flex-grow overflow-hidden">
                <RetentionReportTypePicker
                    selected={selected}
                    toggleSelect={toggleSelect}
                    onChange={handleChangeParams}
                    params={params}
                />
                <EmailList
                    reportTypeSelected={Boolean(selected.length)}
                    onSubmit={onSubmit}
                />
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(false)}
                action={
                    <IconButton style={{ marginRight: 20 }} aria-label="close" color="inherit" onClick={() => setOpen(false)}>
                        <Icon>close</Icon>
                    </IconButton>
                }
            >
                <Alert variant="filled" onClose={() => setOpen(false)} severity="info" style={{ paddingRight: 30 }}>
                    <div className="flex items-center">
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: mailSend,
                                rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
                            }}
                            height={120}
                            width={120}
                        />
                        <Typography className="px-4">{"Sus reportes se estan enviando..."}</Typography>
                    </div>
                </Alert>
            </Snackbar>
        </div>
    )
}

export default SendReports
