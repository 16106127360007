import { FIELDS } from "lib"

export const SUPPPLIER_TABLE_COLUMNS = [
    { id: FIELDS.comercialName.key, label: FIELDS.comercialName.name },
    { id: FIELDS.identification.key, label: FIELDS.identification.name },
    { id: FIELDS.identificationType.key, label: FIELDS.identificationType.name },
    { id: FIELDS.taxPlansCount.key, label: FIELDS.taxPlansCount.name },
    { id: FIELDS.physical.key, label: FIELDS.physical.name },
    { id: FIELDS.sriAutomatic.key, label: FIELDS.sriAutomatic.name },
    { id: FIELDS.manualTaxes.key, label: FIELDS.manualTaxes.name },
    { id: FIELDS.especificText.key, label: FIELDS.especificText.name },
    { id: FIELDS.active.key, label: FIELDS.activeAlt.name }
]

export const SUPPLIER_TABS = [
    { key: 0, label: FIELDS.generalInfo.name },
]

export const SUPPLIER_TYPES = [
    {
      value: 'Retenciones',
    },
    {
      value: 'Órdenes de Compra',
    },
    {
      value: 'Ambos',
    },
  ]

export const SUPPLIER_CONTACT_TYPES = [
    { name: 'Notificaciones de Pago', key: 0 },
    { name: 'Rechazos de Facturas', key: 1 },
    { name: 'Envío de Comprobantes de Retención Electrónicos', key: 2 },
    { name: 'Anulación de Comprobantes de Retención Electrónicos', key: 3 },
    { name: 'Gerente Financiero', key: 4 },
    { name: 'Conciliación de Cuenta', key: 5 },
    { name: 'Ejecutivo de Cuenta', key: 6 },
  ]