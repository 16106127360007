export const ROOT_TITLE = "Documentos DHL"
export const OFFICE_TITLE = "Sucursales"
export const CUSTOMER_TITLE = "Clientes"
export const CATEGORY_TITLE = "Categorias"
export const SUPPLIER_TITLE = "Proveedores"
export const ACCOUNT_TYPE_TITLE = "Tipos de cuenta"
export const IDENTIFICATION_TYPE_TITLE = "Tipos de identificacion"
export const TAX_TITLE = "Impuestos"
export const TAX_TYPE_TITLE = "Tipos de impuesto"
export const TAX_PLAN_TITLE = "Plan de Impuestos"
export const REJECTION_CAUSE_TITLE = "Motivos de Rechazo"
export const MODULE_VIEW_TITLE = "Vistas por módulo"
export const USER_MODULE_TITLE = "Usuarios por módulo"
export const MATRIX_TITLE = "Matrices"
export const USER_TITLE = "Usuarios"
export const CONFIGURATION_TITLE = "Configuración"
export const MODULE_TITLE = "Módulos"
export const VIEW_TITLE = "Vistas"
export const DOCUMENT_TITLE = "Documentos"
export const NEW_DOCUMENT_TITLE = "Nuevo documento"
export const NEW_RETENTION_TITLE = "Nueva retención"
export const NEW_PURCHASE_ORDER_TITLE = "Nueva solicitud de orden de compra"
export const DOCUMENT_VIEW_TITLE = "Ver documento"
export const PURCHASE_ORDER_TITLE = "Ordenes de compra"
export const PURCHASE_ORDER_VIEW_TITLE = "Ver orden de compra"
export const APPROVAL_LEVEL_TITLE = "Niveles de aprobación"
export const SEND_REPORT_TITLE = "Enviar reporte"
export const REJECTED_TITLE = "Rechazados"
export const HISTORY_TITLE = "Historial"
export const EXCEPTION_TITLE = "Excepción"
export const PAYMENTS_TITLE = "Pagos"
export const AUXILIAR_TITLE = "Carga Auxiliar"
