import React from 'react'
import { FormControlLabel, Radio, Typography } from '@material-ui/core'
import { RETENTION_REPORT_TYPES } from 'lib'
import { IRetentionReportParams } from 'types'
import RetentionReportParams from 'components/RetentionReportParams/RetentionReportParams'

interface Props {
    selected: number[]
    toggleSelect: (typeId: number) => void
    onChange: (key: string, value: any) => void
    params: IRetentionReportParams
}

const RetentionReportTypePicker = (props: Props) => {
    const { selected, toggleSelect, onChange, params } = props
    return (
        <div className="flex flex-grow flex-col h-full mr-4 bg-transparent">
            <Typography className="pb-3 pt-4" color="textSecondary" variant="body2" style={{ fontWeight: 700, letterSpacing: 0.5 }}>
                {"Reportes y parámetros"}
            </Typography>
            <div className="flex flex-grow flex-col">
                {
                    RETENTION_REPORT_TYPES.map(reportType => (
                        <div className="flex-grow my-1 pl-4">
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={selected.includes(reportType.id)}
                                        size="small"
                                        onClick={() => toggleSelect(reportType.id)}
                                    />
                                }
                                label={
                                    <Typography
                                        variant="button"
                                        style={{ letterSpacing: 0.5 }}
                                    >
                                        {reportType.name}
                                    </Typography>
                                }
                            />
                            <RetentionReportParams
                                type={reportType.type}
                                disabled={!selected.includes(reportType.id)}
                                reportKey={reportType.key}
                                params={params}
                                onChange={onChange}
                            />
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default RetentionReportTypePicker
