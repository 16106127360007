import { IClosingDate } from "types"

export const ClosingDateListMock : IClosingDate[] = [
    {
        year: 2021,
        closingMonths: [
            {
                closingDateId: 1,
                month: 0,
            },
            {
                closingDateId: 1,
                month: 1,
            },
            {
                closingDateId: 1,
                month: 2,
            },
            {
                closingDateId: 1,
                month: 3,
            },
            {
                closingDateId: 1,
                month: 4,
            },
            {
                closingDateId: 1,
                month: 5,
            },
            {
                closingDateId: 1,
                month: 6,
            },
            {
                closingDateId: 1,
                month: 7,
            },
            {
                closingDateId: 1,
                month: 8,
            },
            {
                closingDateId: 1,
                month: 9,
            },
            {
                closingDateId: 1,
                month: 10,
            },
            {
                closingDateId: 1,
                month: 11,
                openingDate: new Date('12/01/2021'),
                closingDate: new Date()
            },
        ]
    }
]