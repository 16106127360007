import React from 'react'
import { Checkbox, Typography, TextField, Icon, InputAdornment, IconButton, Tooltip } from '@material-ui/core'
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import { Autocomplete } from '@material-ui/lab'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { DocumentContext } from 'context'
import { FIELDS, RETENTION_REPORT_CONDITIONS_IDS } from 'lib'
import { IRetentionReportParams } from 'types'

type ParamsType = "year-month" | "state-types-documentDate-receptionDate"

interface Props {
    type: ParamsType | string
    disabled: boolean
    reportKey: string
    onChange: (key: string, value: any) => void
    params: IRetentionReportParams
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const mediumInputStyle = { width: "49%" }

const RetentionReportParams = (props: Props) => {
    const { type, disabled, params, reportKey, onChange } = props
    const { historyTypes } = React.useContext(DocumentContext)
    const allHistoryTypesSelected = React.useCallback(() => {
        return historyTypes.filter(c => RETENTION_REPORT_CONDITIONS_IDS.includes(c.historyTypeId)).length === params.state.conditions.length && params.state.conditions.length !== 0
    }, [historyTypes, params.state.conditions])
    return (
        <div className="w-full flex flex-wrap pt-4">
            {
                type === "state-documentDate-receptionDate" &&
                <React.Fragment>
                    <div className="w-full flex items-center mb-2">
                        <Autocomplete
                            multiple
                            options={historyTypes.filter(c => RETENTION_REPORT_CONDITIONS_IDS.includes(c.historyTypeId))}
                            disableCloseOnSelect
                            getOptionLabel={(option) => (option.name)}
                            fullWidth
                            disabled={disabled}
                            value={historyTypes.filter(c => params.state.conditions.includes(c.historyTypeId))}
                            openOnFocus
                            limitTags={3}
                            classes={{ input: "autocomplete-p-s" }}
                            size="small"
                            style={{ ...mediumInputStyle, width: "100%" }}
                            ChipProps={{ size: "small" }}
                            onChange={(_, selectedValue) => {
                                onChange(
                                    reportKey,
                                    { ...params.state, conditions: selectedValue.map(c => c.historyTypeId) }
                                )
                            }}
                            renderOption={(option) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8, height: 35 }}
                                        checked={Boolean(params.state.conditions.includes(option.historyTypeId))}
                                        size="small"
                                    />
                                    <Typography variant="body2">
                                        {option.name}
                                    </Typography>
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    fullWidth
                                    label={FIELDS.flowStatus.name}
                                    autoComplete="off"
                                />
                            )}
                        />
                        <Tooltip arrow title={allHistoryTypesSelected() ? "Quitar todos" : "Selecionar todos"}>
                            <IconButton
                                onClick={() => {
                                    onChange(
                                        reportKey,
                                        { ...params.state, conditions: allHistoryTypesSelected() ? [] : historyTypes.filter(c => RETENTION_REPORT_CONDITIONS_IDS.includes(c.historyTypeId)).map(h => h.historyTypeId) }
                                    )
                                }}
                            >
                                <Icon fontSize="small">{allHistoryTypesSelected() ? "indeterminate_check_box" : "check_box_outline_blank"}</Icon>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div
                        className="flex justify-between mb-2 flex items-start"
                        style={{ ...mediumInputStyle, width: "100%" }}
                    >
                        <KeyboardDatePicker
                            value={params.state.documentStart}
                            helperText={FIELDS.documentDate.name}
                            FormHelperTextProps={{ style: { margin: 0 } }}
                            onChange={(date) => {
                                onChange(
                                    reportKey,
                                    { ...params.state, documentStart: date }
                                )
                            }}
                            style={{ ...mediumInputStyle, marginTop: 0, marginRight: 10 }}
                            emptyLabel={FIELDS.emptyDate.name}
                            inputVariant="outlined"
                            fullWidth
                            invalidDateMessage={"Fecha inválida"}
                            disabled={disabled}
                            size="small"
                            label={FIELDS.startDate.name}
                            variant="inline"
                            format="dd/MM/yyyy"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon fontSize="small" color="action">event</Icon>
                                    </InputAdornment>
                                ),
                            }}
                            onBlur={() => {
                                if (!params.state.documentStart || isNaN(params.state.documentStart.getTime())) {
                                    onChange(
                                        reportKey,
                                        { ...params.state, documentStart: new Date() }
                                    )
                                }
                            }}
                        />
                        <KeyboardDatePicker
                            value={params.state.documentEnd}
                            onChange={(date) => {
                                onChange(
                                    reportKey,
                                    { ...params.state, documentEnd: date }
                                )
                            }}
                            style={{ ...mediumInputStyle, marginTop: 0 }}
                            emptyLabel={FIELDS.emptyDate.name}
                            inputVariant="outlined"
                            fullWidth
                            invalidDateMessage={"Fecha inválida"}
                            disabled={disabled}
                            size="small"
                            label={FIELDS.endDate.name}
                            variant="inline"
                            format="dd/MM/yyyy"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon fontSize="small" color="action">event</Icon>
                                    </InputAdornment>
                                ),
                            }}
                            onBlur={() => {
                                if (!params.state.documentEnd || isNaN(params.state.documentEnd.getTime())) {
                                    onChange(
                                        reportKey,
                                        { ...params.state, documentEnd: new Date() }
                                    )
                                }
                            }}
                        />
                        <Tooltip arrow title={"Reiniciar fechas"}>
                            <IconButton
                                onClick={() => {
                                    onChange(
                                        reportKey,
                                        { ...params.state, documentEnd: new Date(), documentStart: new Date() }
                                    )
                                }}
                            >
                                <Icon fontSize="small">{"clear_all"}</Icon>
                            </IconButton>
                        </Tooltip>
                    </div>
                </React.Fragment>
            }
            {
                type === "documentDate-receptionDate" &&
                <React.Fragment>
                    <div
                        className="flex justify-between mb-2 flex items-start"
                        style={{ ...mediumInputStyle, width: "100%" }}
                    >
                        <KeyboardDatePicker
                            value={params[reportKey].documentStart}
                            helperText={FIELDS.documentDate.name}
                            FormHelperTextProps={{ style: { margin: 0 } }}
                            onChange={(date) => {
                                
                                onChange(
                                    reportKey,
                                    { ...params[reportKey], documentStart: date }
                                )
                            }}
                            style={{ ...mediumInputStyle, marginTop: 0, marginRight: 10 }}
                            emptyLabel={FIELDS.emptyDate.name}
                            inputVariant="outlined"
                            fullWidth
                            invalidDateMessage={"Fecha inválida"}
                            disabled={disabled}
                            size="small"
                            label={FIELDS.startDate.name}
                            variant="inline"
                            format="dd/MM/yyyy"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon fontSize="small" color="action">event</Icon>
                                    </InputAdornment>
                                ),
                            }}
                            onBlur={() => {
                                if (!params[reportKey].documentStart || isNaN(params[reportKey].documentStart.getTime())) {
                                    onChange(
                                        reportKey,
                                        { ...params[reportKey], documentStart: new Date() }
                                    )
                                }
                            }}
                        />
                        <KeyboardDatePicker
                            value={params[reportKey].documentEnd}
                            onChange={(date) => {
                                onChange(
                                    reportKey,
                                    { ...params[reportKey], documentEnd: date }
                                )
                            }}
                            style={{ ...mediumInputStyle, marginTop: 0 }}
                            emptyLabel={FIELDS.emptyDate.name}
                            inputVariant="outlined"
                            fullWidth
                            invalidDateMessage={"Fecha inválida"}
                            disabled={disabled}
                            size="small"
                            label={FIELDS.endDate.name}
                            variant="inline"
                            format="dd/MM/yyyy"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon fontSize="small" color="action">event</Icon>
                                    </InputAdornment>
                                ),
                            }}
                            onBlur={() => {
                                if (!params[reportKey].documentEnd || isNaN(params[reportKey].documentEnd.getTime())) {
                                    onChange(
                                        reportKey,
                                        { ...params[reportKey], documentEnd: new Date() }
                                    )
                                }
                            }}
                        />
                        <Tooltip arrow title={"Reiniciar fechas"}>
                            <IconButton
                                onClick={() => {
                                    onChange(
                                        reportKey,
                                        { ...params[reportKey], documentEnd: new Date(), documentStart: new Date() }
                                    )
                                }}
                            >
                                <Icon fontSize="small">{"clear_all"}</Icon>
                            </IconButton>
                        </Tooltip>
                    </div>
                </React.Fragment>
            }
        </div>
    )
}

export default RetentionReportParams
