import { Button, Paper, Snackbar, Typography } from '@material-ui/core'
import { StartEndStaticPicker, YearMonthPicker, ClosingYearSummary, FixedLoadingIndicator, UnsavedChangesConfirm } from 'components'
import { ClosingYearSummaryRef } from 'components/ClosingYearSummary/ClosingYearSummary'
import { UnsavedChangesConfirmRef } from 'components/UnsavedChangesConfirm/UnsavedChangesConfirm'
import { ROOT_TITLE, CONFIGURATION_TITLE, getClosingDatesService, saveClosingDateService } from 'lib'
import React from 'react'
import { Helmet } from 'react-helmet'
import { IClosingDate, IMessageConfig } from 'types'
import Lottie from 'react-lottie'
import event from 'assets/animations/event.json'
import { Alert } from '@material-ui/lab'

interface Props {

}

const Configuration = (props: Props) => {

    const summaryRef = React.useRef<ClosingYearSummaryRef>(null)
    const unsavedChangesConfirmRef = React.useRef<UnsavedChangesConfirmRef>(null)
    const [closingDates, setClosingDates] = React.useState<IClosingDate[]>([])
    const [initialState, setInitialState] = React.useState<IClosingDate[]>([])
    const [savedMonth, setSavedMonth] = React.useState<number | undefined>(undefined)
    const [savedDate, setSavedDate] = React.useState<number | undefined>(undefined)
    const [selectedDate, setSelectedDate] = React.useState<number>(0)
    const [selectedMonth, setSelectedMonth] = React.useState<number>(new Date().getMonth())
    const [loading, setLoading] = React.useState(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const [unsavedChanges, setUnsavedChanges] = React.useState(false)
    console.log(closingDates)

    const handleMonthChange = async (selected: number) => {
        if (unsavedChanges) {
            setSavedMonth(selected)
            unsavedChangesConfirmRef.current?.open()
            return
        }
        else {
            setSelectedMonth(selected)
        }
    }

    const handleSave = async () => {
        const currentClosingDate = closingDates[selectedDate]
        const { closingMonths, year } = currentClosingDate
        const closingMonth = closingMonths[selectedMonth]
        const { month, openingDate, closingDate, closingDateId } = closingMonth
        try {
            await saveClosingDate(month, year, openingDate ?? null, closingDate ?? null, closingDateId)
            checkSaved()
            setUnsavedChanges(false)
            setMessageConfig({ open: true, message: "Fechas actualizadas", severity: "success" })
        } catch (error) {
            setMessageConfig({ open: true, message: "No se pudo acyualizar las fechas...", severity: "error" })
        }
    }

    const checkSaved = () => {
        if (savedMonth !== undefined) {
            setSelectedMonth(savedMonth)
            setSavedMonth(undefined)
        }
        if (savedDate !== undefined) {
            setSelectedDate(() => {
                const newIndex = savedDate
                const nonEmptyMonth = closingDates[newIndex].closingMonths.findIndex(c => Boolean(c.closingDate) || Boolean(c.openingDate))
                if (nonEmptyMonth !== -1) { setSelectedMonth(nonEmptyMonth) }
                return newIndex
            })
            setSavedDate(undefined)
        }
    }

    const handleDiscard = async () => {
        setClosingDates(initialState)
        checkSaved()
        setUnsavedChanges(false)
    }

    const disableChanges = React.useCallback(() => {
        return selectedMonth < new Date().getMonth() && closingDates[selectedDate].year <= new Date().getFullYear()
    }, [selectedDate, closingDates, selectedMonth])

    const handleOpeningChange = (date: Date) => {
        if (disableChanges()) return
        if (date.getDate() < new Date().getDate() && date.getFullYear() === new Date().getFullYear()) {
            setMessageConfig({ open: true, message: "No se pueden escoger fechas menores al día actual", severity: "error" })
            return
        }
        const currentClosing = closingDates[selectedDate].closingMonths[selectedMonth].closingDate
        if (currentClosing && date.getTime() >= new Date(currentClosing).getTime()) {
            setMessageConfig({ open: true, message: "No se pueden escoger fechas mayor al cierre de mes", severity: "error" })
            return
        }
        setClosingDates(current => {
            current[selectedDate].closingMonths[selectedMonth].openingDate = date
            return [...current]
        })
        setUnsavedChanges(true)
    }

    const handleClosingChange = (date: Date) => {
        if (disableChanges()) return
        const currentOpening = closingDates[selectedDate].closingMonths[selectedMonth].openingDate
        if (currentOpening && date.getTime() <= new Date(currentOpening).getTime()) {
            setMessageConfig({ open: true, message: "No se pueden escoger fechas menores al día de apertura", severity: "error" })
            return
        }
        setClosingDates(current => {
            current[selectedDate].closingMonths[selectedMonth].closingDate = date
            return [...current]
        })
        setUnsavedChanges(true)
    }

    const saveClosingDate = async (month: number, year: number, openingDate: Date | null, closingDate: Date | null, closingDateId?: number) => {
        try {
            setLoading(true)
            await saveClosingDateService({ month, year, closingDate, openingDate, closingDateId })
            await getList()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const handleNext = async () => {
        if (selectedDate === closingDates.length - 1) {
            const { year } = closingDates[selectedDate]
            const newYear = year + 1
            await saveClosingDateService({ month: 0, year: newYear, openingDate: null, closingDate: null })
            await getList()
            setSelectedDate(current => current + 1)
            setSelectedMonth(0)
        } else {
            handleChangeDate(1)
        }
    }

    const handleChangeDate = async (delta: number) => {
        if (unsavedChanges) {
            setSavedDate(selectedDate + delta)
            unsavedChangesConfirmRef.current?.open()
            return
        }
        else {
            setSelectedDate(current => {
                const newIndex = current + delta
                const nonEmptyMonth = closingDates[newIndex].closingMonths.findIndex(c => Boolean(c.closingDate) || Boolean(c.openingDate))
                if (nonEmptyMonth !== -1) { setSelectedMonth(nonEmptyMonth) }
                return newIndex
            })
        }
    }

    const handleOpenMonth = () => {
        saveClosingDate(new Date().getMonth(), new Date().getFullYear(), new Date(), closingDates[selectedDate]?.closingMonths[selectedMonth]?.closingDate ?? null)
    }

    const handlePrev = () => handleChangeDate(-1)

    const getList = React.useCallback(async () => {
        try {
            setLoading(true)
            const response = await getClosingDatesService()
            // console.log(response)
            setClosingDates(response)
            setInitialState(response)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }, [])

    React.useEffect(() => {
        getList()
    }, [getList])

    return (
        <Paper className="flex h-full overflow-hidden pb-0">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${CONFIGURATION_TITLE}`}</title>
            </Helmet>
            {
                Boolean(closingDates.length) ?
                    <React.Fragment>
                        <YearMonthPicker
                            closingDate={closingDates[selectedDate]}
                            selectedDate={selectedDate}
                            selectedMonth={selectedMonth}
                            disabledPrev={selectedDate === 0}
                            onMonthChange={handleMonthChange}
                            onNext={handleNext}
                            onPrev={handlePrev}
                            disabled={loading}
                        />
                        <StartEndStaticPicker
                            openingDate={closingDates[selectedDate].closingMonths[selectedMonth]?.openingDate}
                            closingDate={closingDates[selectedDate].closingMonths[selectedMonth]?.closingDate}
                            onMonthChange={handleMonthChange}
                            selectedMonth={selectedMonth + 1}
                            selectedYear={closingDates[selectedDate].year}
                            disabled={disableChanges() || loading}
                            onOpeningChange={handleOpeningChange}
                            onClosingChange={handleClosingChange}
                            onOpenMonth={handleOpenMonth}
                        />
                        <div className='mr-4 mt-4' style={{ width: 180 }}>
                            <Button
                                color="primary"
                                variant="contained"
                                disableElevation
                                style={{ marginTop: 10 }}
                                size="small"
                                fullWidth
                                disabled={!unsavedChanges}
                                onClick={handleSave}
                            >
                                {"Guardar cambios"}
                            </Button>
                            <Button
                                style={{ marginTop: 10 }}
                                variant="contained"
                                disableElevation
                                size="small"
                                fullWidth
                                onClick={() => summaryRef.current?.open()}
                            >
                                {"Ver resumen"}
                            </Button>
                        </div>
                        <ClosingYearSummary
                            ref={summaryRef}
                            closingDates={closingDates}
                            selectedDate={selectedDate}
                        />
                    </React.Fragment>
                    :
                    <React.Fragment>
                        {
                            !loading &&
                            <div className='w-full flex flex-col items-center justify-center'>
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: event,
                                        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
                                    }}
                                    height={250}
                                    width={300}
                                />
                                <Typography
                                    className="px-4 pb-10 pt-4"
                                    variant="h5"
                                    color='textSecondary'

                                >
                                    {"No existen cierres de mes creados"}
                                </Typography>
                                <Button
                                    disableElevation
                                    size="large"
                                    color="primary"
                                    variant='contained'
                                    onClick={() => saveClosingDate(new Date().getMonth(), new Date().getFullYear(), null, null)}
                                >
                                    {"Crear"}
                                </Button>
                            </div>
                        }
                    </React.Fragment>
            }
            <FixedLoadingIndicator loading={loading} />
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <UnsavedChangesConfirm
                ref={unsavedChangesConfirmRef}
                onDiscard={handleDiscard}
                onSave={handleSave}
            />
        </Paper>
    )
}

export default Configuration
