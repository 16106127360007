import { uniqueListByKey, FIELDS } from "lib"
import { IDetailSummary, ITax, IDetail } from "types"

export const getDetailSummary = (detail: IDetail[]): IDetailSummary[] => {
    let detailSummary: IDetailSummary[] = []
    let taxes: ITax[] = []
    for (const d of detail) { if (d.tax) { taxes.push(d.tax) } }
    taxes = uniqueListByKey(taxes, FIELDS.taxId.key)
    const taxesSubtotal: IDetailSummary[] = []
    for (const tax of taxes) {
        const summary: IDetailSummary = { label: tax.description, value: 0, addsToTotal: true, substractsFromTotal: false }
        for (const d of detail) {
            if (d.tax && d.tax.taxId === tax.taxId) {
                const taxValue = summary.value + (d.taxesValue ? d.taxesValue : (d.unitPrice && d.quantity ? (d.unitPrice * d.quantity) * (tax.percentage / 100) : 0))
                summary.value = parseFloat(taxValue.toFixed(2))
            }
        }
        taxesSubtotal.push(summary)
    }
    detailSummary = [...detailSummary].concat(taxesSubtotal)
    const subtotalSummary: IDetailSummary[] = []
    for (const tax of taxes) {
        const summary: IDetailSummary = { label: `Subtotal ${tax.percentage}%`, value: 0, addsToTotal: true, substractsFromTotal: false }
        for (const d of detail) {
            if (d.tax && d.tax.taxId === tax.taxId) {
                const totalValue = summary.value + (d.taxesValue ? d.taxesValue : (d.unitPrice && d.quantity ? (d.unitPrice * d.quantity) : 0))
                summary.value = parseFloat(totalValue.toFixed(2))
            }
        }
        subtotalSummary.push(summary)
    }
    detailSummary = [...detailSummary].concat(subtotalSummary)
    const valueNoTaxesSummary: IDetailSummary = { label: "Subtotal sin impuestos", value: 0, addsToTotal: subtotalSummary.length === 0, substractsFromTotal: false }
    for (const d of detail) {
        const totalValue = (d.subtotalNoTaxes ? parseFloat(d.subtotalNoTaxes.toString()) : (d.unitPrice && d.quantity ? (d.unitPrice * d.quantity) : 0))
        valueNoTaxesSummary.value = valueNoTaxesSummary.value + parseFloat(totalValue.toFixed(2))
    }
    detailSummary.push(valueNoTaxesSummary)
    const taxesSummary: IDetailSummary = { label: "Valor impuestos", value: 0, addsToTotal: taxesSubtotal.length === 0, substractsFromTotal: false }
    for (const d of detail) {
        if (!d.tax) {
            taxesSummary.value = taxesSummary.value + (d.taxesValue ? parseFloat(d.taxesValue.toString()) : 0)
        }
    }
    detailSummary.push({ ...taxesSummary, value: parseFloat(taxesSummary.value.toFixed(2)) })
    const discountSummary: IDetailSummary = { label: "Descuento", value: 0, addsToTotal: false, substractsFromTotal: true }
    for (const d of detail) {
        discountSummary.value = discountSummary.value + (d.discount ? parseFloat(d.discount.toString()) : 0)
    }
    detailSummary.push(discountSummary)
    const total: IDetailSummary = { label: "Total", value: 0, addsToTotal: false, substractsFromTotal: false }
    for (const summary of detailSummary) {
        if (summary.addsToTotal) {
            total.value = total.value + summary.value
        }
        if (summary.substractsFromTotal) {
            total.value = total.value - summary.value
        }
    }
    detailSummary.push({ ...total, value: parseFloat(total.value.toFixed(2)) })
    return detailSummary
}

export const getDetailSummaryFromData = (data: any): IDetailSummary[] => {
    let detailSummary: IDetailSummary[] = []
    console.log(data)
    return detailSummary    
}