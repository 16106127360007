import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, Icon, IconButton, Tooltip, Snackbar, Drawer, Divider } from '@material-ui/core'
import { FIELDS, formatCurrency, MAX_FILE_SIZE, REIMBURSEMENT_RANGE_TOLERANCE } from 'lib'
import React from 'react'
import { IDocument, IDocumentFile, IMessageConfig, IRefund, IReimbursement, IUploadDocumentFile } from 'types'
import moment from 'moment'
import 'moment/locale/es'
import { useDropzone } from 'react-dropzone'
import { Alert } from '@material-ui/lab'
import { ConfirmDialog, ReimbursementForm } from 'components'
import { FileContext } from 'context'

moment.locale("es")

interface Props {
    refunds: IRefund[]
    associated: IDocument[]
    files: IDocumentFile[]
    onAddDocumentFiles?: (newDocuments: IDocumentFile[]) => void
    documentId: number
    edit?: boolean
    onAddReimbusement?: (reimbursement: IReimbursement) => void
    readOnly?: boolean
    skipAuthorizationValidation?: boolean
}

const headerCellStyle = { fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2 }
const bodyCellStyle = { fontSize: "0.8em", letterSpacing: 0.2 }

const RefundData = (props: Props) => {
    const { refunds, associated, files, onAddDocumentFiles, documentId, edit, onAddReimbusement, readOnly, skipAuthorizationValidation } = props
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const [authorization, setAuthorization] = React.useState<string | undefined>()
    const [documentFile, setDocumentFile] = React.useState<IUploadDocumentFile | undefined>()
    const [selected, setSelected] = React.useState<Partial<IRefund> | undefined>(undefined)
    const { uploadDocumentFiles } = React.useContext(FileContext)
    const handleUpload = (authorizationDoc: string) => {
        setAuthorization(authorizationDoc)
        handleOpenUploader()
    }
    const handleConfirmUpload = async () => {
        if (documentFile) {
            const documentFiles = await uploadDocumentFiles([documentFile], documentId)
            if (onAddDocumentFiles) {
                onAddDocumentFiles(documentFiles)
            }
        }
        return true
    }
    const onDrop = React.useCallback(acceptedFiles => {
        if (acceptedFiles[0]) {
            if ((acceptedFiles[0] as File).size > MAX_FILE_SIZE) {
                setMessageConfig({ message: "El tamaño del archivo excede el máximo permitido", open: true, severity: "error" })
                return
            }
            setDocumentFile({ file: acceptedFiles[0], description: authorization ?? "" })
        }
    }, [authorization])
    const { getInputProps, open: handleOpenUploader } = useDropzone({ onDrop, multiple: false, onFileDialogCancel: () => setAuthorization(undefined) })
    const getStatus = React.useCallback((authorization: string) => {
        const inFiles = files?.find(f => f.description === authorization)
        const inAssociated = associated?.find(a => a.documentAuthorization === authorization)
        return Boolean(inFiles) || Boolean(inAssociated)
    }, [associated, files])
    return (
        <React.Fragment>
            <Typography className="pl-2 pt-2" style={{ fontWeight: 600, letterSpacing: 0.5, color: "#b1b1b1" }} variant="caption">
                {`Datos de las facturas de sustento`}
            </Typography>
            <Paper variant="outlined" className={`mt-3 w-full`} elevation={1}>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={headerCellStyle} align="left">{FIELDS.authorizationNumber.name}</TableCell>
                                <TableCell style={headerCellStyle} align="left">{FIELDS.identification.name}</TableCell>
                                <TableCell style={headerCellStyle} align="left">{FIELDS.sequential.name}</TableCell>
                                <TableCell style={headerCellStyle} align="left">{FIELDS.emissionDate.name}</TableCell>
                                <TableCell style={headerCellStyle} align="right">{FIELDS.rate.name}</TableCell>
                                <TableCell style={headerCellStyle} align="right">{FIELDS.taxRefund.name}</TableCell>
                                <TableCell style={headerCellStyle} align="right">{FIELDS.taxSupport.name}</TableCell>
                                <TableCell style={headerCellStyle} align="right">{ }</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                refunds.map(row => (
                                    <TableRow>
                                        <TableCell style={bodyCellStyle} align="left">{row.authorizationDoc}</TableCell>
                                        <TableCell style={bodyCellStyle} align="left">{row.identification}</TableCell>
                                        <TableCell style={bodyCellStyle} align="left">{row.sequential}</TableCell>
                                        <TableCell style={bodyCellStyle} align="left">{moment(row.emitionDate, "DD/MM/YYYY").format('DD-MM-YYYY').toString()}</TableCell>
                                        <TableCell style={bodyCellStyle} align="right">{formatCurrency(parseFloat(row.rate))}</TableCell>
                                        <TableCell style={bodyCellStyle} align="right">{formatCurrency(row.taxRefund)}</TableCell>
                                        <TableCell style={bodyCellStyle} align="right">{formatCurrency(row.taxSupport)}</TableCell>
                                        <TableCell style={bodyCellStyle} align="right">
                                            {
                                                getStatus(row.authorizationDoc) ?
                                                    <div className="flex items-center">
                                                        <Icon color="secondary" fontSize="small">check_circle</Icon>
                                                        {
                                                            (edit && !readOnly) &&
                                                            <Tooltip arrow title="Editar reembolso">
                                                                <IconButton style={{ marginLeft: 10 }} onClick={() => setSelected(row)} size="small">
                                                                    <Icon style={{ fontSize: "1rem" }} color="action" fontSize="small">edit</Icon>
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                    </div>
                                                    :
                                                    <div className="flex items-center">
                                                        <Icon color="error" fontSize="small">cancel</Icon>
                                                        {
                                                            !readOnly &&
                                                            <React.Fragment>
                                                                <Tooltip arrow title="Subir archivo">
                                                                    <IconButton onClick={() => handleUpload(row.authorizationDoc)}>
                                                                        <Icon color="action" fontSize="small">publish</Icon>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                {
                                                                    edit &&
                                                                    <Tooltip arrow title="Editar reembolso">
                                                                        <IconButton onClick={() => { setSelected(row) }} size="small">
                                                                            <Icon style={{ fontSize: "1rem" }} color="action" fontSize="small">edit</Icon>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                }
                                                                <input id={row.authorizationDoc} key={row.authorizationDoc} {...getInputProps()} />
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <ConfirmDialog
                open={Boolean(documentFile?.file && documentFile?.description)}
                onCancel={() => setDocumentFile(undefined)}
                onConfirm={handleConfirmUpload}
                title={`¿Esta seguro de subir archivo para documento con número de autorización ${authorization}?`}
            />
            <Drawer onClose={() => setSelected(undefined)} anchor="right" open={Boolean(selected)}>
                {
                    selected &&
                    <div style={{ width: 600 }} className="h-full flex flex-col">
                        <div className="flex flex-wrap items-center p-4 relative">
                            <IconButton onClick={() => setSelected(undefined)} className="absolute">
                                <Icon color="action">
                                    {"chevron_left"}
                                </Icon>
                            </IconButton>
                            <Typography className="uppercase pl-2" color="textSecondary" variant="h6" align="center" style={{ fontWeight: 600, letterSpacing: 0.25 }}>
                                {"Editar reembolso"}
                            </Typography>
                        </div>
                        <Divider variant="middle" />
                        <ReimbursementForm
                            skipAuthorizationValidation={skipAuthorizationValidation}
                            withDetails={false}
                            onAdd={(newReimbursement) => {
                                if (onAddReimbusement) {
                                    onAddReimbusement(newReimbursement)
                                }

                            }}
                            supplierIdentification=""
                            validation={(newReimbursement) => {
                                const selectedRefund = refunds.find(r => r.authorizationDoc === selected.authorizationDoc)
                                if (selectedRefund) {
                                    //@ts-ignore
                                    const newTaxSupport = parseFloat(newReimbursement.iva ?? "0") + parseFloat(newReimbursement.subtotalRateTwelve ?? "0") + parseFloat(newReimbursement.subtotalRateZero ?? "0") + parseFloat(newReimbursement.subtotalNoTaxesObject ?? "0")
                                    if (newTaxSupport <= (selectedRefund.taxSupport + selectedRefund.taxRefund + REIMBURSEMENT_RANGE_TOLERANCE)
                                        &&
                                        newTaxSupport >= (selectedRefund.taxSupport + selectedRefund.taxRefund - REIMBURSEMENT_RANGE_TOLERANCE)
                                    ) {
                                        return true
                                    } else {
                                        setMessageConfig({ message: `El rango permitido para el valor del sustento es de ± ${REIMBURSEMENT_RANGE_TOLERANCE} centavos`, open: true, severity: "error" })
                                        return false
                                    }
                                }
                                return true
                            }}
                            onClose={() => { setSelected(undefined) }}
                            edit={undefined}
                            initialValue={selected}
                            defaultCloseOnAdd={Boolean(selected)}
                        />
                    </div>
                }
            </Drawer>
        </React.Fragment>
    )
}

export default RefundData
