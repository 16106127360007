import React from 'react'
import { Alert } from "@material-ui/lab"
import { Button, Icon, Menu, MenuItem, Snackbar, TablePagination, Tooltip, TableSortLabel, TextField, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { ITaxPlan, TaxPlanStateFilter, IMessageConfig } from 'types'
import { useInputValue, useTableModel } from 'hooks'
import { useHistory } from 'react-router'
import { ManagementContext } from 'context'
import { TAX_PLAN_PATH, NEW_PATH, RESOURCES_PATH, EDIT_PATH, deleteTaxPlanService, SMALL_ROWS_PER_PAGINATION_OPTIONS, ROOT_TITLE, TAX_PLAN_TITLE, TAXPLAN_TABLE_MAIN_COLUMNS, TAXPLAN_TABLE_RETENTION_COLUMNS, RECEIVED_PATH } from 'lib'
import { FixedLoadingIndicator, TaxPlanForm, ConfirmDialog, Search, FormDialog, ServerErrorHandler, LabelDisplayedRows } from 'components'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

const Row = (props: { row: ITaxPlan, index: number, setActionsAnchorEl: React.Dispatch<null | HTMLElement>, setSelected: React.Dispatch<any> }) => {
  const { row, index, setSelected, setActionsAnchorEl } = props
  const [open, setOpen] = React.useState(false)
  const { taxTypes } = React.useContext(ManagementContext)
  return (
    <React.Fragment>
      <TableRow key={row.taxPlanId}>
        <TableCell width={40}>
          <Tooltip title="Ver Impuestos" arrow>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              <Icon fontSize="small">{open ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell padding="none" align="left" component="th" scope="row">{row.name}</TableCell>
        <TableCell padding="none" align="left" component="th" scope="row">{row.description}</TableCell>
        <TableCell padding="none" align="left"><span className={row.active ? "active-state-label" : "inactive-state-label"}>{row.active ? "Activo" : "Inactivo"}</span></TableCell>
        <TableCell align="right">
          <IconButton onClick={(e) => { setActionsAnchorEl(e.currentTarget); setSelected(row) }} size="small">
            <Icon fontSize="small">more_vert</Icon>
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} style={{ padding: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div id={`slip-table-${index}`} className="flex h-full flex-col overflow-y-hidden my-4" style={{ maxHeight: "calc(100vh - 110px)" }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {TAXPLAN_TABLE_RETENTION_COLUMNS.map(headerCell => (
                      <TableCell padding="none">{headerCell.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    row.taxCodes.map((row) => {
                      return (
                        <TableRow
                          key={row.taxId}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          selected
                        >
                          <TableCell padding="none" align="left">{taxTypes.find(t => t.taxId.toString() === row.taxTypeId.toString())?.name}</TableCell>
                          <TableCell padding="none" align="left">{row.taxCode}</TableCell>
                          <TableCell padding="none" align="left">{row.description}</TableCell>
                          <TableCell padding="none" align="left">{`${row.percentage.toFixed(2)}%`}</TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

interface Props {
  forCustomers: boolean
}

const TaxPlan = (props: Props) => {
  const { forCustomers } = props
  const { taxPlans, refreshTaxPlans, refreshTaxes, refreshTaxTypes } = React.useContext(ManagementContext)
  const { value: query, clearValue: handleClear, onChange: handleChange } = useInputValue()
  const [filter, setFilter] = React.useState<TaxPlanStateFilter>("active")
  const { createSortHandler, handleChangePage, handleChangeRowsPerPage, order, orderBy, page, rows, rowsPerPage } = useTableModel()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<boolean>(false)
  const location = useLocation()
  const history = useHistory()
  const [selected, setSelected] = React.useState<any | undefined>(undefined)
  const [actionsAnchorEl, setActionsAnchorEl] = React.useState<null | HTMLElement>(null)
  const [deleteId, setDeleteId] = React.useState<number>(-1)
  const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
  const getModel = React.useCallback((): ITaxPlan[] => {
    const src = filter === "all" ? [...taxPlans] : [...taxPlans].filter(o => ((o.active === (filter === "active") ? true : false) && o.forCustomers === (forCustomers ? true : false)))
    if (Boolean(query)) {
      return taxPlans.filter((s: any) => {
        return s.name.toUpperCase().includes(query.toUpperCase())
          || s.description.toUpperCase().includes(query.toUpperCase())
      })
    }
    return src
  }, [taxPlans, query, filter, forCustomers])

  const handleEdit = () => {
    setActionsAnchorEl(null)
    history.push({
      state: {
        edit: true,
        values: selected,
      },
      pathname: `${forCustomers ? RECEIVED_PATH : RESOURCES_PATH}${TAX_PLAN_PATH}${EDIT_PATH}`,
    })
  }
  const handleConfirmDelete = (id: number) => {
    setActionsAnchorEl(null)
    setDeleteId(id)
  }
  const handleDelete = async () => {
    try {
      setLoading(true)
      await deleteTaxPlanService(deleteId)
      setMessageConfig({
        open: true,
        message: "Plan de Impuestos eliminado!",
        severity: "success",
      })
      await refreshTaxPlans()
      setLoading(false)
      return true
    } catch (error) {
      setMessageConfig({
        open: true,
        message: "No se puede eliminar el Plan de Impuestos...",
        severity: "error",
      })
      setLoading(false)
      return false
    }
  }
  React.useEffect(() => {
    const initScreen = async () => {
      try {
        setLoading(true)
        await refreshTaxPlans()
        await refreshTaxes()
        await refreshTaxTypes()
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setError(true)
      }
    }
    initScreen()
  }, [refreshTaxPlans, refreshTaxes, refreshTaxTypes])

  return (
    <Paper className="flex flex-col h-full overflow-hidden p-4 pb-0">
      <Helmet>
        <title>{`${ROOT_TITLE} - ${TAX_PLAN_TITLE}`}</title>
      </Helmet>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <Search
            onChange={handleChange}
            query={query}
            onClear={handleClear}
            placeholer={"Buscar por código o descripción..."}
          />
          <TextField
            size="small"
            variant="outlined"
            style={{ width: 140, marginLeft: 15 }}
            classes={{ root: "filter-text-field" }}
            select
            label="Estado"
            value={filter}
            onChange={(e) => setFilter(e.target.value as TaxPlanStateFilter)}
          >
            <MenuItem dense value={"active"}>{"Activas"}</MenuItem>
            <MenuItem dense value={"inactive"}>{"Inactivas"}</MenuItem>
            <MenuItem dense value={"all"}>{"Todas"}</MenuItem>
          </TextField>
        </div>
        <Button
          size="small"
          variant="contained"
          color="primary"
          disableElevation
          onClick={() =>
            history.push(forCustomers ? `${RECEIVED_PATH}${TAX_PLAN_PATH}${NEW_PATH}` : `${RESOURCES_PATH}${TAX_PLAN_PATH}${NEW_PATH}`)
          }
        >
          <Icon fontSize="small" style={{ marginRight: 5 }}>add</Icon>
          {"Nuevo"}
        </Button>
        <FormDialog
          open={Boolean(
            location.pathname ===
            `${RESOURCES_PATH}${TAX_PLAN_PATH}${NEW_PATH}` ||
            location.pathname ===
            `${RESOURCES_PATH}${TAX_PLAN_PATH}${EDIT_PATH}` ||
            location.pathname ===
            `${RECEIVED_PATH}${TAX_PLAN_PATH}${NEW_PATH}` ||
            location.pathname ===
            `${RECEIVED_PATH}${TAX_PLAN_PATH}${EDIT_PATH}`
          )}
          onClose={() => history.push(forCustomers ? `${RECEIVED_PATH}${TAX_PLAN_PATH}` : `${RESOURCES_PATH}${TAX_PLAN_PATH}`)}
        >
          <TaxPlanForm
            forCustomers={forCustomers}
            onSuccess={() => {
              setMessageConfig({
                open: true,
                message: Boolean(selected)
                  ? "Plan de Impuestos actualizado!"
                  : "Plan de Impuestos registrado!",
                severity: "success",
              })
              refreshTaxPlans()
              if (Boolean(selected)) {
                setSelected(undefined)
              }
            }}
            onError={() =>
              setMessageConfig({
                open: true,
                message: "No se puede registrar el Plan de Impuestos",
                severity: "error",
              })
            }
            onClose={() => history.push(forCustomers ? `${RECEIVED_PATH}${TAX_PLAN_PATH}` : `${RESOURCES_PATH}${TAX_PLAN_PATH}`)}
          />
        </FormDialog>
      </div>
      <div className="flex h-full flex-col mt-4 overflow-hidden">
        <div className="flex flex-grow overflow-auto">
          <TableContainer>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell padding={"none"} />
                  {
                    TAXPLAN_TABLE_MAIN_COLUMNS.map(headCell => (
                      <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={"none"}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={createSortHandler(headCell.id as any)}
                        >
                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <span className="hidden">
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))
                  }
                  <TableCell padding={"none"} />
                </TableRow>
              </TableHead>
              <TableBody>
                {(rows(getModel()) as ITaxPlan[]).map((row, index) => {
                  return (
                    <Row key={"taxPlanRow" + index} row={row} index={index} setActionsAnchorEl={setActionsAnchorEl} setSelected={setSelected} />
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Menu
            anchorEl={actionsAnchorEl}
            open={Boolean(actionsAnchorEl)}
            onClose={() => setActionsAnchorEl(null)}
          >
            <MenuItem onClick={handleEdit} dense>{"Editar"}</MenuItem>
            <MenuItem onClick={() => handleConfirmDelete(selected?.taxPlanId ?? -1)} dense>{"Eliminar"}</MenuItem>
          </Menu>
        </div>
        <div>
          <TablePagination
            rowsPerPageOptions={SMALL_ROWS_PER_PAGINATION_OPTIONS}
            component="div"
            count={getModel().length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Planes de Impuesto por página"
            labelDisplayedRows={LabelDisplayedRows}
          />
        </div>
      </div>
      <ConfirmDialog
        onCancel={() => setDeleteId(-1)}
        onConfirm={handleDelete}
        open={Boolean(deleteId !== -1)}
        title={"¿Estás seguro que deseas eliminar?"}
        loading={loading}
      />
      <Snackbar
        open={messageConfig.open}
        autoHideDuration={6000}
        onClose={() => setMessageConfig({ ...messageConfig, open: false })}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Alert
          variant="filled"
          onClose={() => setMessageConfig({ ...messageConfig, open: false })}
          severity={messageConfig.severity}
        >
          {messageConfig.message}
        </Alert>
      </Snackbar>
      <FixedLoadingIndicator loading={loading && Boolean(deleteId === -1)} />
      <ServerErrorHandler
        error={error}
        onSuccess={() => setError(false)}
        tryAgain={refreshTaxPlans}
      />
    </Paper>

  )
}
export default TaxPlan
