import { IDocumentFilter, IDocumentProcessedFilters, IPurchaseOrderFilter, IPurchaseOrderProcessedFilters } from "types"

export const transformDocumentFilters = (filters: IDocumentFilter): IDocumentProcessedFilters => {
    return {
        conditions: filters.conditions.map(c => c.conditionId).join(","),
        documentRangeEnd: filters.documentRange.end ? new Date(filters.documentRange.end).toString() : "",
        documentRangeStart: filters.documentRange.start ? new Date(filters.documentRange.start).toString() : "",
        documentTypes: filters.documentTypes.map(d => d.documentTypeId).join(","),
        receptionRangeEnd: filters.receptionRange.end ? new Date(filters.receptionRange.end).toString() : "",
        receptionRangeStart: filters.receptionRange.start ? new Date(filters.receptionRange.start).toString() : "",
        rucOrName: filters.rucOrName,
        serialNumber: filters.serialNumber,
        rucOrNameReceptor: filters.rucOrNameReceptor,
        historyTypes: filters.historyTypes.map(h => h.historyTypeId).join(","),
    }
}

export const transformPurchaseOrderFilters = (filters: IPurchaseOrderFilter): IPurchaseOrderProcessedFilters => {
    return {
        conditions: filters.conditions.map(c => c.conditionId).join(","),
    }
}