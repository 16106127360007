import { grey, red } from '@material-ui/core/colors'
import { createTheme } from '@material-ui/core/styles'

const primaryMainColor = "#D40511"
const primaryLightColor = "#ffcdd2"
const secondaryMainColor = "#FFCC00"
const secondaryLightColor = "#FFE57F"
const black = "#000000"

const theme = createTheme({
    palette: {
        primary: {
            main: primaryMainColor,
            light: primaryLightColor
        },

        secondary: {
            main: secondaryMainColor,
            light: secondaryLightColor
        }
    },
    typography: {
        fontFamily: 'Open Sans',
    },
    overrides: {
        MuiListItem: {
            root: {
                '&$selected, &$selected:hover': {
                    backgroundColor: red[600],
                    color: "white"
                },
                borderRadius: 4,
                fontSize: "0.85em"
            },
            button: {
                '&:hover': {
                    backgroundColor: grey[100],
                }
            }
        },
        MuiTooltip: {
            arrow: {
                color: black
            },
            tooltip: {
                backgroundColor: black
            }
        },
        MuiButton: {
            sizeSmall: {
                fontSize: "0.85em",
            },
        },
        MuiTableCell: {
            head: {
                fontWeight: 600,
                color: grey[500],
                letterSpacing: 0.5,
                lineHeight: "2em",
            }
        },
        MuiFormControlLabel: {
            label: {
                fontSize: "0.9em",
                marginLeft: 8
            }
        },
        MuiList: {
            padding: {
                padding: 2
            },
            dense: {
                padding: 2
            }
        },
        MuiListItemText: {
            primary: {
                fontSize: "0.8rem",
                letterSpacing: 1
            }
        },
        MuiAccordionSummary: {
            content: {
                '&$expanded': {
                    margin: "15px 0px"
                }
            },
            root: {
                '&$expanded': {
                    minHeight: "auto"
                }
            }
        },
        MuiFilledInput: {
            root: {
                backgroundColor: grey[100]
            }
        },
        MuiTab: {
            root: {
                '&$selected': {
                    backgroundColor: "#FFF"
                },
            }
        },
        MuiAvatar: {
            colorDefault: {
                backgroundColor: primaryMainColor
            }
        }
    }
})


export default theme